import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {

  function render() {
    return (
      <div>
        <h5 className="bold">¿Tienes dudas?</h5>
        <ul className="list-unstyled">
          <li><Link to={`/contacto`}>Contáctanos</Link></li>
          <li><Link to={`/ingresar`}>Ingresar</Link></li>
        </ul>
      </div>

    )
  }

  return (
    <div className="footer">
      <div className="container">

        <div className="row justify-content-center">
          <div className="col-6 col-sm-3">
            <div className="logo-section">
              <img src="/logo_192.png" alt="Logo" className="logo-footer" />
            </div>
            <div >
              <ul className="logos">
                <li><a href="https://api.whatsapp.com/send?phone=+5213319999015&text=Hola!%20Me%20gustar%C3%ADa%20realizar%20una%20consulta." className="logo-self" target="_blank" rel="noreferrer">
                  <i className="fa fa-whatsapp"></i>
                </a></li>
                <li><a href="https://www.facebook.com/cristina.corona.33865854" className="logo-self" target="_blank" rel="noreferrer">
                  <i className="fa fa-facebook-square"></i>
                </a></li>
                <li><a href="tel:+523319999015" className="logo-self" target="_blank" rel="noreferrer">
                  <i className="fa fa-phone-square"></i>
                </a></li>
              </ul>
            </div>
            <br />
          </div>

          <div className="col-6 col-sm-3 info-section">
            <h5 className="bold">Farmacia Homeopática San Rafael Arcángel</h5>
            <ul className="list-unstyled">
              <li>Medicina homeópata enfocada en ayudar al paciente a recuperar la salud, de manera suave, rápida, y permanente.</li>
            </ul>
            <br />
          </div>

          <div className="col-6 col-sm-3 info-section">
            <h5 className="bold">Información</h5>
            <ul className="list-unstyled">
              <li><Link to={`/inicio`}>Inicio</Link></li>
              <li><Link to={`/homeopatia`}>Homeopatía</Link></li>
              <li><Link to={`/productos`}>Productos</Link></li>
              <li><Link to={`/terminos-y-condiciones`}>Términos y Condiciones</Link></li>
              <li><Link to={`/aviso-de-privacidad`}>Aviso de Privacidad</Link></li>
            </ul>
          </div>

          <div className="col-6 col-sm-3 social-section">
            {render()}
          </div>

        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-auto">
            <p>© 2024 | Farmacia Homeopática San Rafael Arcángel</p>
            <br />
          </div>

        </div>
      </div>
    </div>
  );
}

export default Footer;
