import React, { Component } from 'react';
import Question from './Question';

class FAQs extends Component {

    render() {
        let questionsList = [
            {
                id: "q01",
                title: "¿Qué pasa si necesito un medicamento que no manejan en la farmacia?",
                content: "Se realiza un pedido especial y se puede crear el medicamento al momento, en caso de no tener el medicamento necesario para el pacedimiento puede tardar de 8 a 10 días en crearlo dependiendo del laboratorio."
            },
            {
                id: "q02",
                title: "¿Cuánto tiempo tarda el medicamento homeopático en tener efecto?",
                content: "Notarás cambios desde las primeras tomas, notando una mayor mejoría a partir del quinto día."
            },
            {
                id: "q03",
                title: "Si no vivo en Guadalajara ¿Cómo puedo adquirir un paquete o producto?",
                content: "Envíanos tu pedido a traves de Whatsapp, te realizamos una cotización y tu pedido llegara a la puerta de tu hogar."
            },
            {
                id: "q04",
                title: "¿Todas las personas pueden tomar homeopatía?",
                content: "Si, la homeopatía no se contrapone con ningún tratamiento alopático y no causa efectos secundarios en embarazo y lactancia."
            },
            {
                id: "q05",
                title: "¿Cómo cuido mi medicamento homeopático?",
                content: "No es necesario mantenerlos en refrigeración, solo tenerlos en un lugar fresco y evitar que les den los rayos del sol directamente."
            },
            {
                id: "q06",
                title: "¿Puedo beber café durante mi tratamiento homeopático?",
                content: "Sí, no existe una evidencia de que el café antidote el efecto del medicamento; es recomendable evitar el uso de té de menta o algún producto con alcanfor; en caso de tomar un medicamento antes de el aseo bucal, es necesario esperar solo unos minutos despues de haberlo ingerido."
            },
            {
                id: "q07",
                title: "¿Qué diferencia existe entre una dilución y un preparado?",
                content: "Una dilución es el medicamento sellado y concentrado, este a su vez debe diluirse; mientras un preparado, se le realiza en farmacia y se entrega listo para tomarse."
            },
            {
                id: "q08",
                title: "¿Puedo tratar a mi mascota con homeopatía?",
                content: "Sí, los animales son sumamente reactivos a la misma."
            }
        ];

        let questions = questionsList
            .map(qt => {
                return (
                    <Question
                        key={qt.id}
                        question={qt.title}
                        information={qt.content}
                    />
                );
            });


        return (
            <div className="container container-75">
                <div className=" card-body">
                    <center>
                        <h2 className="bold primary-green">Preguntas Frecuentes</h2>
                        <div className="border-bottom-header"></div>
                    </center>


                    <div className="container faqs">
                        <div className=" card-body">
                            {questions}
                        </div>
                    </div>

                    <span className="space-2"></span>
                </div>
            </div>
        );
    }
}

export default FAQs;
