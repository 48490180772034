import React from 'react';

function Terms(props) {

    (function init() {
        window.scrollTo(0, 0)
    })();

    return (
        <div>
            <br /><br />
            <div className="container">
                <div className="card card-body">
                    <center><h2 className="bold">TÉRMINOS Y CONDICIONES</h2></center>
                    <br />
                    <h5 className="mb-0 mt-0 uppercase bold">TÉRMINOS Y CONDICIONES GENERALES DE VENTA POR MEDIOS ELECTRÓNICOS (“E-COMMERCE”) DE FARMACIA HOMEOPÁTICA SAN RAFAEL ARCÁNGEL.</h5>
                    <br />
                    <ul className="list-yes">
                        <li>Los presentes Términos y Condiciones Generales de Venta por Medios Electrónicos (en lo sucesivo las “Condiciones Generales”) son aplicables al acceso y/o uso del
                            Sitio Web de www.homeopaticasanrafael.com por lo que hace a la contratación por medios electrónicos (“e-commerce”) para la compra de productos. Sitio Farmacia
                            Homeopática San Rafael Arcángel opera con las disposiciones establecidas en los artículos 76 BIS y 76 BIS I de la Ley Federal de Protección al Consumidor y la
                            Norma Mexicana NMX-COE-001-SCFI 2018.</li>
                        <br />
                        <li>Cualquier persona que acceda, se registre y/o use el Sitio Farmacia Homeopática San Rafael Arcángel (en lo sucesivo el “Usuario”) en relación con la contratación
                            por medios electrónicos, por este hecho acepta y expresa su consentimiento de someterse a las presentes Condiciones Generales por el simple hecho de acceder y navegar
                            en el Sitio Farmacia Homeopática San Rafael Arcángel. Asimismo, por este hecho el Usuario se compromete a revisar de manera periódica las presentes Condiciones
                            Generales que se encuentran disponibles para su consulta dentro del Sitio Farmacia Homeopática San Rafael Arcángel para conocer los cambios y/o modificaciones a
                            los mismos y, de así considerarlo conveniente, consentir con los mismos.</li>
                        <br />
                        <li>El Sitio Farmacia Homeopática San Rafael Arcángel es propiedad exclusiva de Farmacia Homeopática San Rafael Arcángel, por lo cual todo visitante se compromete a
                            no intentar distribuir, alterar o modificar contenido alguno del mismo.</li>
                        <br />
                        <li>Cualquier información o material que el Usuario comparta a Farmacia Homeopática San Rafael Arcángel a través del Sitio Farmacia Homeopática San Rafael Arcángel es
                            y será tratada como información no confidencial, excepto la información que sea personal en términos de la Ley Federal de Protección de Datos Personales en Posesión
                            de los Particulares.</li>
                        <br />
                        <li>Farmacia Homeopática San Rafael Arcángel no busca con la información contenida en este sitio ofender a persona alguna, sector de la población o entidad gubernamental,
                            ya que toda imagen, información, animación o video, entre otros, es incluida en este sitio web únicamente con el fin de dar a conocer los productos comercializados por
                            Farmacia Homeopática San Rafael Arcángel.</li>
                        <br />
                        <li>Farmacia Homeopática San Rafael Arcángel no discrimina a ninguna persona por cuestiones de religión, orientación sexual, situación económica y social o cualesquiera otras.
                            Por lo tanto, la información contenida en el Sitio Farmacia Homeopática San Rafael Arcángel no puede ser interpretada como discriminatoria en ningún sentido y Farmacia
                            Homeopática San Rafael Arcángel no será responsable por interpretaciones diversas a lo aquí señalado.</li>
                        <br />
                        <li>Farmacia Homeopática San Rafael Arcángel podrá modificar, agregar y/o eliminar el contenido de las presentes Condiciones Generales, sin necesidad de previo aviso,
                            iniciando la vigencia de los cambios desde el momento de su publicación, siendo exclusiva responsabilidad del Usuario asegurarse de tomar conocimiento de tales modificaciones
                            y, de así considerarlo conveniente, consentir con los mismos. El hecho de que el Usuario realice dentro del Sitio Farmacia Homeopática San Rafael Arcángel cualquier acto
                            relacionado con la contratación por medios electrónicos se entenderá como una aceptación expresa a las modificaciones de que se trate.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Capacidad</h4>
                    <br />
                    <ul className="list-yes">
                        <li>El contenido y servicios que ofrece el Sitio Farmacia Homeopática San Rafael Arcángel están reservados y dirigidos
                            únicamente a personas que tengan capacidad legal para contratar, en términos del artículo 1789 del Código Civil Federal
                            y sus correlativos a los demás estados de los Estados Unidos Mexicanos.</li>
                        <br />
                        <li>La conducta de los menores de edad y personas con incapacidad legal que ingresen al Sitio Farmacia Homeopática San Rafael
                            Arcángel queda bajo la estricta responsabilidad de los padres, tutores o mayor de edad a cuyo cargo se encuentran,
                            en términos de lo establecido en los artículos 1919, 1920 y 1921 del Código Civil Federal y sus correlativos a los demás
                            estados de los Estados Unidos Mexicanos.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Authorización</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Farmacia Homeopática San Rafael Arcángel otorga y concede al Usuario el derecho no exclusivo, revocable y no transferible de
                            usar el Sitio Farmacia Homeopática San Rafael Arcángel para llevar a cabo cualquier acto relacionado con la contratación
                            por medios electrónicos de conformidad con las presentes Condiciones Generales.</li>
                        <br />
                        <li>El Usuario sólo podrá imprimir y/o copiar cualquier información contenida o publicada en el Sitio Farmacia Homeopática San Rafael Arcángel
                            exclusivamente para su uso personal, no comercial.</li>
                        <br />
                        <li>La reimpresión, republicación, distribución, asignación, sublicencia, venta, reproducción electrónica o aprovechamiento por cualquier
                            otro medio de cualquier información, documento o gráfico que aparezca en el Sitio Farmacia Homeopática San Rafael Arcángel,
                            en todo o en parte, para cualquier uso distinto al personal no comercial está expresamente prohibido, a menos que Farmacia
                            Homeopática San Rafael Arcángel le haya otorgado al Usuario una autorización previamente y por escrito.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">COMERCIALIZACIÓN DE PRODUCTOS</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Farmacia Homeopática San Rafael Arcángel publicará en el Sitio Farmacia Homeopática San Rafael Arcángel
                            los productos y el precio total que el Usuario deberá pagar por ellos. Los productos enunciados en la
                            tienda en línea de Farmacia Homeopática San Rafael Arcángel, se ofrecen en las cantidades presentaciones
                            disponibles al momento de la compra. Para efectuar la compra, el consumidor, deberá ingresar al Sitio
                            Farmacia Homeopática San Rafael Arcángel y seleccionar los productos de interés, debiendo llenar un
                            formulario en el cual se le requerirán algunos datos personales incluyendo un correo electrónico para
                            contactar al consumidor durante el proceso de la compra. Los productos seleccionados por el consumidor
                            serán incluidos en el “carrito de compra”. Al finalizar la selección de productos, el consumidor deberá
                            presionar el botón “Solicitar” y/o “Comprar” para tener acceso a la página de resumen del pedido en la
                            que podrá identificar precio, descripción y cantidad de los productos seleccionados, o bien, corregir o
                            modificar su pedido. Al final de la página de resumen del pedido, el consumidor deberá seleccionar la
                            forma de pago, ya sea mediante OXXO PAY, MERCADOPAGO o PAYPAL para que Farmacia Homeopática San Rafael
                            Arcángel pueda procesar la compraventa de su pedido. Una vez terminado este procedimiento, Farmacia
                            Homeopática San Rafael Arcángel notificará al consumidor la confirmación del pedido de los productos a
                            través de un correo electrónico que más abajo se indica.</li>
                        <br />
                        <li>De igual manera, se indicará si tienen alguna promoción y/o descuento y la vigencia del mismo, así como
                            los medios habilitados para liquidar el pago de dichos productos.</li>
                        <br />
                        <li>No existirá contrato alguno entre el Usuario y Farmacia Homeopática San Rafael Arcángel con relación a
                            cualquier artículo hasta que su pedido haya sido confirmado por Farmacia Homeopática San Rafael Arcángel
                            y el pago del precio correspondiente haya sido acreditado por Farmacia Homeopática San Rafael Arcángel.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase">LIMITACIÓN DE RESPONSABILIDAD</h4>
                    <br />
                    <ul className="list-yes">
                        <li>El uso que el consumidor haga del Sitio Farmacia Homeopática San Rafael Arcángel es por su propia cuenta y riesgo.
                            Ni Farmacia Homeopática San Rafael Arcángel, ni ninguno de sus empleados, responsables, directores o agentes,
                            ni ninguna otra parte involucrada en la creación, la producción o la distribución del Sitio Farmacia Homeopática
                            San Rafael Arcángel serán responsables de ningún daño directo, indirecto, especial, consecuencial, ni de otros
                            daños que resulten del uso del Contenido en el Sitio Farmacia Homeopática San Rafael Arcángel, o de la
                            imposibilidad de utilizarlo, incluidos los daños causados por virus informáticos, incorrecciones, información
                            incompleta del Sitio Farmacia Homeopática San Rafael Arcángel, o el rendimiento de los productos, o cualquier
                            otro daño derivado de o en relación con estas Condiciones Generales.</li>
                        <br />
                        <li>En paquetes: Traslados a sitios diferentes de los que se encuentran especificados.</li>
                        <br />
                        <li>Gastos extras de ningún tipo que no estén debidamente especificados en los itinerarios.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">FALLAS EN EL SISTEMA</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Farmacia Homeopática San Rafael Arcángel no se responsabiliza por cualquier daño,
                            perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet.
                            Farmacia Homeopática San Rafael Arcángel tampoco será responsable por cualquier virus que pudiera
                            infectar el equipo del Usuario como consecuencia del acceso, uso o examen de su sitio web o a
                            raíz de cualquier transferencia de datos, archivos, imágenes, textos o audio contenidos en el
                            mismo. Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante
                            en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet.
                            Farmacia Homeopática San Rafael Arcángel no garantiza el acceso y uso continuado o ininterrumpido
                            de su Sitio Farmacia Homeopática San Rafael Arcángel. El sistema puede eventualmente no estar
                            disponible debido a dificultades técnicas o fallas de Internet o por cualquier otra circunstancia
                            ajena a Farmacia Homeopática San Rafael Arcángel; en tales casos se procurará restablecerlo con la
                            mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. Farmacia
                            Homeopática San Rafael Arcángel no será responsable por ningún error u omisión contenidos en su sitio web.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">DOMICILIO</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Para efectos de las presentes Condiciones Generales, se fija como domicilio de Farmacia Homeopática San Rafael
                            Arcángel el ubicado en Calle Paseos de La Primavera 84, Arenales Tapatíos, C.P. 45066, en Zapopan, Jalisco.</li>
                    </ul>

                    <br /><br />
                </div>
            </div>
        </div>
    );
}

export default Terms;
