import React, { Component } from 'react';
import { PreviewCatalog, Afecciones } from '../../components';
import ProductsList from './ProductsList';

class Products extends Component {

    render() {

        (function init() {
            window.scrollTo(0, 0)
        })();

        return (
            <div>
                <div className="wallpaperProductos">
                    <div className="textAbout">
                        <h3 id="about" className="fadeInRight animated">PRODUCTOS</h3>
                    </div>
                </div>

                <div className="container container-75">
                    <div className=" card-body">
                        <center>
                            <h2 className="bold">CONTAMOS CON UNA AMPLIA GAMA DE PRODUCTOS</h2>
                            <br />
                            <h3 className="bold primary-green">¡AL CUIDADO DE TU SALUD!</h3>
                            <div className="border-bottom-header"></div>
                            <br />
                            <PreviewCatalog
                                url="https://firebasestorage.googleapis.com/v0/b/homeopatica-sanrafael.appspot.com/o/catalogo%2FCatalogo-HomeopaticaSanRafael.pdf?alt=media&token=ec2679f9-69cb-4385-9c9e-068d12215843"
                            />
                            <br />
                            <br />
                        </center>

                        <ProductsList></ProductsList>

                        <br /><br />

                        <center>
                            <h2 className="bold">Tratamos cualquier tipo de afección</h2>
                            <br />
                        </center>

                        <Afecciones></Afecciones>


                        <div className="container faqs">
                            <div className=" card-body">

                            </div>
                        </div>

                        <span className="space-2"></span>
                    </div>
                </div>

            </div >
        );
    }
}

export default Products;
