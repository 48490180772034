import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavOpen: false,
      yPosition: 0,
      collapse: true,
    };
  }

  componentDidMount() {
    $(".iMobile").off("click").on("click", function () {
      $("#menu").toggleClass("targetMobileMenu");
      $("#menu-overlay").toggleClass("hidden");
    });

    $(".lnk-menu").off("click").on("click", function () {
      $("#menu").toggleClass("targetMobileMenu");
      $("#menu-overlay").toggleClass("hidden");
    });

  }

  render() {

    return (

      <header className="">
        <div id="controlHeader" className="container lastHeader">
          <div className="brand bounceInDown animated">
            <img src="/logo_192.png" alt="Logo" className="logo" />
            <h4>Homeopática San Rafael</h4>
          </div>

          <div className="alignMenu">
            <nav className="dropdownmenu">
              <ul>
                <li>
                  <NavLink to={`/inicio`}>
                    Inicio
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/homeopatia`}>
                    Homeopatía
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/productos`}>
                    Productos
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/contacto`}>
                    Contacto
                  </NavLink></li>
              </ul>
            </nav>
          </div>

        </div>


        <div className="container desap">
          <i className="toggle-menu"><i className="fa fa-bars iMobile"></i></i>
          <nav id="menu">
            <i className="toggle-menu"><i className="fa fa-times iMobile"></i></i>
            <ul>
              <li>
                <NavLink to={`/inicio`} className="lnk-menu">
                  Inicio
                </NavLink>
              </li>
              <li>
                <NavLink to={`/homeopatia`} className="lnk-menu">
                  Homeopatía
                </NavLink>
              </li>
              <li>
                <NavLink to={`/productos`} className="lnk-menu">
                  Catálogo de productos
                </NavLink>
              </li>
              <li>
                <NavLink to={`/contacto`} className="lnk-menu">
                  Contacto
                </NavLink></li>
              <br />
              <li>
                <a href="https://maps.app.goo.gl/DuukERQa2c1sMPhi7" target="_blank" rel="noopener noreferrer" className="lnk-menu">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icons-small" id="map-header" viewBox="0 0 512 512">
                    <g>
                      <path d="m499.924 113.264c-173.716-34.527-162.471-32.724-166.405-32.062-1.258.21-16.676 3.309-52.613 10.496-15.066-43.444-56.399-74.721-104.906-74.721-48.552 0-89.918 31.336-104.949 74.844l-53.127-10.557c-9.26-1.836-17.924 5.243-17.924 14.713v352c0 7.157 5.057 13.317 12.076 14.712 172.248 34.236 160.971 32.026 162.113 32.165 3.622.445-7.572 2.297 161.82-31.582l158.067 31.417c9.265 1.839 17.924-5.252 17.924-14.712v-352c0-7.157-5.057-13.317-12.076-14.713zm-323.924-66.287c44.663 0 81 36.336 81 81 0 37.683-60.298 133.338-81 164.989-20.679-31.616-81-127.298-81-164.989 0-44.664 36.337-81 81-81zm-146 67.273 35.21 6.997c-.134 2.227-.21 4.469-.21 6.729 0 25.014 16.363 65.381 50.025 123.409 19.656 33.883 39.033 62.974 45.975 73.221v137.095l-131-26.037zm161 210.356c6.941-10.247 26.319-39.337 45.975-73.221 33.662-58.028 50.025-98.395 50.025-123.408 0-2.304-.079-4.59-.218-6.859l34.218-6.845v321.407l-130 26zm291 137.096-131-26.037v-321.414l131 26.037z">
                      </path>
                      <path d="m176 174.977c25.916 0 47-21.084 47-47s-21.084-47-47-47-47 21.084-47 47 21.084 47 47 47zm0-64c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z">
                      </path>
                    </g>
                  </svg>
                  Ver ubicación
                </a>
              </li>
              <li>
                <a href="tel:+52331-999-9015" target="_blank" rel="noreferrer" className="lnk-menu">
                  <i className="fa fa-phone"></i> Llamar Ahora
                </a></li>
              <li><a href="https://www.facebook.com/cristina.corona.33865854" target="_blank" rel="noopener noreferrer" className="lnk-menu" >
                <i className="fa fa-facebook"></i> Abrir Facebook
              </a></li>
              <br />
              <li><div className="lnk-menu-noAction" >
                <svg xmlns="http://www.w3.org/2000/svg" className="svg-icons-small" id="map-header" viewBox="0 0 512 512">
                  <g>
                    <path d="m499.924 113.264c-173.716-34.527-162.471-32.724-166.405-32.062-1.258.21-16.676 3.309-52.613 10.496-15.066-43.444-56.399-74.721-104.906-74.721-48.552 0-89.918 31.336-104.949 74.844l-53.127-10.557c-9.26-1.836-17.924 5.243-17.924 14.713v352c0 7.157 5.057 13.317 12.076 14.712 172.248 34.236 160.971 32.026 162.113 32.165 3.622.445-7.572 2.297 161.82-31.582l158.067 31.417c9.265 1.839 17.924-5.252 17.924-14.712v-352c0-7.157-5.057-13.317-12.076-14.713zm-323.924-66.287c44.663 0 81 36.336 81 81 0 37.683-60.298 133.338-81 164.989-20.679-31.616-81-127.298-81-164.989 0-44.664 36.337-81 81-81zm-146 67.273 35.21 6.997c-.134 2.227-.21 4.469-.21 6.729 0 25.014 16.363 65.381 50.025 123.409 19.656 33.883 39.033 62.974 45.975 73.221v137.095l-131-26.037zm161 210.356c6.941-10.247 26.319-39.337 45.975-73.221 33.662-58.028 50.025-98.395 50.025-123.408 0-2.304-.079-4.59-.218-6.859l34.218-6.845v321.407l-130 26zm291 137.096-131-26.037v-321.414l131 26.037z">
                    </path>
                    <path d="m176 174.977c25.916 0 47-21.084 47-47s-21.084-47-47-47-47 21.084-47 47 21.084 47 47 47zm0-64c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z">
                    </path>
                  </g>
                </svg>
                Tianguis de 9am a 2pm
              </div></li>
              <li>
                <a href="https://maps.app.goo.gl/tYhDQT7GVJK9z51a9" target="_blank" rel="noopener noreferrer" className="lnk-menu hover-orange">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="svg-icons-small color-orange"
                    viewBox="0 0 512 512"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      stroke="none">
                      <path d="M435 4836 c-79 -35 -94 -84 -95 -314 l0 -182 -170 -340 -170 -340 0
                                                    -142 c0 -180 11 -253 48 -333 52 -110 139 -187 257 -227 l50 -17 3 -1040 2
                                                    -1041 -88 0 c-74 0 -96 -4 -139 -25 -105 -53 -133 -120 -133 -322 0 -161 9
                                                    -196 59 -226 27 -16 189 -17 2501 -17 2312 0 2474 1 2501 17 50 30 59 65 59
                                                    226 0 202 -28 269 -133 322 -43 21 -65 25 -139 25 l-88 0 2 1041 3 1042 40 12
                                                    c134 40 233 134 285 270 20 52 23 84 28 245 l5 185 -169 335 -169 335 -5 205
                                                    c-5 225 -11 248 -73 294 l-28 21 -1751 3 -1752 2 -23 -22 c-40 -38 -25 -96 31
                                                    -118 18 -7 578 -10 1737 -10 l1710 0 -3 -147 -3 -148 -2065 0 -2065 0 -3 148
                                                    -3 147 179 0 c105 0 192 5 211 11 54 19 69 79 28 117 -22 21 -31 22 -233 22
                                                    -151 -1 -217 -4 -239 -14z m482 -598 c-2 -7 -44 -124 -93 -260 l-88 -248 -268
                                                    0 -268 0 130 260 130 260 231 0 c181 0 230 -3 226 -12z m630 0 c-2 -7 -28
                                                    -124 -57 -260 l-54 -248 -268 0 c-147 0 -268 4 -268 8 0 4 41 120 90 257 50
                                                    137 90 251 90 252 0 2 106 3 236 3 185 0 235 -3 231 -12z m623 0 c0 -7 -7
                                                    -124 -17 -260 l-17 -248 -273 0 -273 0 5 23 c10 43 105 489 105 493 0 2 106 4
                                                    235 4 181 0 235 -3 235 -12z m639 -160 c6 -95 15 -212 18 -260 l6 -88 -273 0
                                                    -273 0 6 88 c3 48 12 165 18 260 l12 172 237 0 237 0 12 -172z m611 168 c0 -4
                                                    95 -450 105 -493 l5 -23 -273 0 -273 0 -17 248 c-10 136 -17 253 -17 260 0 9
                                                    54 12 235 12 129 0 235 -2 235 -4z m620 1 c0 -1 41 -115 90 -252 50 -137 90
                                                    -253 90 -257 0 -4 -121 -8 -268 -8 l-268 0 -54 248 c-29 136 -55 253 -57 260
                                                    -4 9 46 12 231 12 130 0 236 -1 236 -3z m750 -257 l130 -260 -268 0 -268 0
                                                    -88 248 c-49 136 -91 253 -93 260 -4 9 45 12 226 12 l231 0 130 -260z m-4080
                                                    -548 c0 -135 -1 -140 -31 -201 -54 -107 -149 -159 -274 -149 -103 9 -183 66
                                                    -226 163 -19 41 -23 73 -27 188 l-5 137 282 0 281 0 0 -138z m708 -9 c-3 -129
                                                    -6 -153 -25 -189 -112 -212 -407 -205 -504 11 -19 42 -24 72 -27 188 l-4 137
                                                    282 0 282 0 -4 -147z m712 17 c0 -152 -17 -212 -77 -274 -110 -113 -296 -115
                                                    -400 -5 -65 70 -77 109 -81 267 l-4 142 281 0 281 0 0 -130z m710 7 c0 -152
                                                    -14 -207 -72 -272 -113 -128 -303 -128 -416 0 -58 65 -72 120 -72 272 l0 123
                                                    280 0 280 0 0 -123z m708 -19 c-3 -126 -6 -148 -26 -188 -94 -180 -317 -217
                                                    -455 -74 -60 62 -77 122 -77 274 l0 130 281 0 281 0 -4 -142z m710 5 c-3 -116
                                                    -8 -146 -27 -188 -97 -216 -392 -223 -504 -11 -19 36 -22 60 -25 189 l-4 147
                                                    282 0 282 0 -4 -137z m710 0 c-4 -115 -8 -147 -27 -188 -43 -97 -123 -154
                                                    -226 -163 -125 -10 -220 42 -274 149 -30 61 -31 66 -31 201 l0 138 281 0 282
                                                    0 -5 -137z m-4038 -451 c65 -38 146 -55 240 -50 74 3 98 9 156 38 41 20 90 55
                                                    120 85 l50 52 66 -62 c93 -88 149 -109 293 -110 105 0 114 2 179 34 39 19 90
                                                    55 121 87 l54 54 27 -35 c36 -47 132 -110 198 -129 69 -20 183 -20 252 0 66
                                                    19 162 82 198 129 l27 35 54 -54 c31 -32 82 -68 121 -87 65 -32 74 -34 179
                                                    -34 144 1 200 22 293 110 l66 62 50 -52 c30 -30 79 -65 120 -85 58 -29 82 -35
                                                    156 -38 141 -7 239 30 334 127 l48 49 54 -54 c56 -56 109 -88 178 -108 l41
                                                    -12 3 -1042 2 -1042 -1410 0 -1410 0 0 846 c0 936 3 889 -64 937 l-31 22 -480
                                                    0 c-529 0 -507 3 -552 -63 -17 -26 -18 -74 -21 -884 l-2 -858 -65 0 -65 0 2
                                                    1042 3 1042 41 12 c69 20 122 52 177 108 l52 53 55 -52 c30 -28 71 -61 90 -73z
                                                    m710 -1302 l0 -830 -425 0 -425 0 0 830 0 830 425 0 425 0 0 -830z m3301
                                                    -1009 c29 -29 29 -31 29 -145 l0 -116 -2410 0 -2410 0 0 116 c0 114 0 116 29
                                                    145 l29 29 2352 0 2352 0 29 -29z"/>
                      <path d="M2025 2650 c-23 -11 -50 -35 -60 -52 -19 -32 -20 -54 -20 -668 0
                                                    -592 1 -637 18 -668 10 -18 32 -42 50 -52 31 -19 59 -20 1163 -20 l1131 0 34
                                                    23 c72 47 69 21 69 712 0 593 -1 622 -20 663 -15 34 -29 47 -64 63 -43 19 -65
                                                    19 -525 17 -455 -3 -480 -4 -500 -22 -27 -25 -28 -79 -1 -106 19 -19 33 -20
                                                    490 -20 l470 0 0 -590 0 -590 -1080 0 -1080 0 0 590 0 590 463 0 464 0 21 23
                                                    c27 29 28 67 3 98 l-19 24 -484 3 c-467 2 -484 2 -523 -18z"/>
                      <path d="M1405 1848 c-39 -21 -45 -39 -45 -131 0 -100 7 -119 49 -136 24 -10
                                                    34 -10 59 3 38 20 42 31 42 131 0 99 -4 111 -41 130 -33 18 -38 18 -64 3z"/>
                    </g>
                  </svg>
                  {' '}Sábado: El Sauz
                </a>
              </li>
              <li>
                <a href="https://maps.app.goo.gl/ghty4DumyCnPGU4bA" target="_blank" rel="noopener noreferrer" className="lnk-menu hover-blue">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="svg-icons-small color-blue"
                    viewBox="0 0 512 512"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      stroke="none">
                      <path d="M435 4836 c-79 -35 -94 -84 -95 -314 l0 -182 -170 -340 -170 -340 0
                                                    -142 c0 -180 11 -253 48 -333 52 -110 139 -187 257 -227 l50 -17 3 -1040 2
                                                    -1041 -88 0 c-74 0 -96 -4 -139 -25 -105 -53 -133 -120 -133 -322 0 -161 9
                                                    -196 59 -226 27 -16 189 -17 2501 -17 2312 0 2474 1 2501 17 50 30 59 65 59
                                                    226 0 202 -28 269 -133 322 -43 21 -65 25 -139 25 l-88 0 2 1041 3 1042 40 12
                                                    c134 40 233 134 285 270 20 52 23 84 28 245 l5 185 -169 335 -169 335 -5 205
                                                    c-5 225 -11 248 -73 294 l-28 21 -1751 3 -1752 2 -23 -22 c-40 -38 -25 -96 31
                                                    -118 18 -7 578 -10 1737 -10 l1710 0 -3 -147 -3 -148 -2065 0 -2065 0 -3 148
                                                    -3 147 179 0 c105 0 192 5 211 11 54 19 69 79 28 117 -22 21 -31 22 -233 22
                                                    -151 -1 -217 -4 -239 -14z m482 -598 c-2 -7 -44 -124 -93 -260 l-88 -248 -268
                                                    0 -268 0 130 260 130 260 231 0 c181 0 230 -3 226 -12z m630 0 c-2 -7 -28
                                                    -124 -57 -260 l-54 -248 -268 0 c-147 0 -268 4 -268 8 0 4 41 120 90 257 50
                                                    137 90 251 90 252 0 2 106 3 236 3 185 0 235 -3 231 -12z m623 0 c0 -7 -7
                                                    -124 -17 -260 l-17 -248 -273 0 -273 0 5 23 c10 43 105 489 105 493 0 2 106 4
                                                    235 4 181 0 235 -3 235 -12z m639 -160 c6 -95 15 -212 18 -260 l6 -88 -273 0
                                                    -273 0 6 88 c3 48 12 165 18 260 l12 172 237 0 237 0 12 -172z m611 168 c0 -4
                                                    95 -450 105 -493 l5 -23 -273 0 -273 0 -17 248 c-10 136 -17 253 -17 260 0 9
                                                    54 12 235 12 129 0 235 -2 235 -4z m620 1 c0 -1 41 -115 90 -252 50 -137 90
                                                    -253 90 -257 0 -4 -121 -8 -268 -8 l-268 0 -54 248 c-29 136 -55 253 -57 260
                                                    -4 9 46 12 231 12 130 0 236 -1 236 -3z m750 -257 l130 -260 -268 0 -268 0
                                                    -88 248 c-49 136 -91 253 -93 260 -4 9 45 12 226 12 l231 0 130 -260z m-4080
                                                    -548 c0 -135 -1 -140 -31 -201 -54 -107 -149 -159 -274 -149 -103 9 -183 66
                                                    -226 163 -19 41 -23 73 -27 188 l-5 137 282 0 281 0 0 -138z m708 -9 c-3 -129
                                                    -6 -153 -25 -189 -112 -212 -407 -205 -504 11 -19 42 -24 72 -27 188 l-4 137
                                                    282 0 282 0 -4 -147z m712 17 c0 -152 -17 -212 -77 -274 -110 -113 -296 -115
                                                    -400 -5 -65 70 -77 109 -81 267 l-4 142 281 0 281 0 0 -130z m710 7 c0 -152
                                                    -14 -207 -72 -272 -113 -128 -303 -128 -416 0 -58 65 -72 120 -72 272 l0 123
                                                    280 0 280 0 0 -123z m708 -19 c-3 -126 -6 -148 -26 -188 -94 -180 -317 -217
                                                    -455 -74 -60 62 -77 122 -77 274 l0 130 281 0 281 0 -4 -142z m710 5 c-3 -116
                                                    -8 -146 -27 -188 -97 -216 -392 -223 -504 -11 -19 36 -22 60 -25 189 l-4 147
                                                    282 0 282 0 -4 -137z m710 0 c-4 -115 -8 -147 -27 -188 -43 -97 -123 -154
                                                    -226 -163 -125 -10 -220 42 -274 149 -30 61 -31 66 -31 201 l0 138 281 0 282
                                                    0 -5 -137z m-4038 -451 c65 -38 146 -55 240 -50 74 3 98 9 156 38 41 20 90 55
                                                    120 85 l50 52 66 -62 c93 -88 149 -109 293 -110 105 0 114 2 179 34 39 19 90
                                                    55 121 87 l54 54 27 -35 c36 -47 132 -110 198 -129 69 -20 183 -20 252 0 66
                                                    19 162 82 198 129 l27 35 54 -54 c31 -32 82 -68 121 -87 65 -32 74 -34 179
                                                    -34 144 1 200 22 293 110 l66 62 50 -52 c30 -30 79 -65 120 -85 58 -29 82 -35
                                                    156 -38 141 -7 239 30 334 127 l48 49 54 -54 c56 -56 109 -88 178 -108 l41
                                                    -12 3 -1042 2 -1042 -1410 0 -1410 0 0 846 c0 936 3 889 -64 937 l-31 22 -480
                                                    0 c-529 0 -507 3 -552 -63 -17 -26 -18 -74 -21 -884 l-2 -858 -65 0 -65 0 2
                                                    1042 3 1042 41 12 c69 20 122 52 177 108 l52 53 55 -52 c30 -28 71 -61 90 -73z
                                                    m710 -1302 l0 -830 -425 0 -425 0 0 830 0 830 425 0 425 0 0 -830z m3301
                                                    -1009 c29 -29 29 -31 29 -145 l0 -116 -2410 0 -2410 0 0 116 c0 114 0 116 29
                                                    145 l29 29 2352 0 2352 0 29 -29z"/>
                      <path d="M2025 2650 c-23 -11 -50 -35 -60 -52 -19 -32 -20 -54 -20 -668 0
                                                    -592 1 -637 18 -668 10 -18 32 -42 50 -52 31 -19 59 -20 1163 -20 l1131 0 34
                                                    23 c72 47 69 21 69 712 0 593 -1 622 -20 663 -15 34 -29 47 -64 63 -43 19 -65
                                                    19 -525 17 -455 -3 -480 -4 -500 -22 -27 -25 -28 -79 -1 -106 19 -19 33 -20
                                                    490 -20 l470 0 0 -590 0 -590 -1080 0 -1080 0 0 590 0 590 463 0 464 0 21 23
                                                    c27 29 28 67 3 98 l-19 24 -484 3 c-467 2 -484 2 -523 -18z"/>
                      <path d="M1405 1848 c-39 -21 -45 -39 -45 -131 0 -100 7 -119 49 -136 24 -10
                                                    34 -10 59 3 38 20 42 31 42 131 0 99 -4 111 -41 130 -33 18 -38 18 -64 3z"/>
                    </g>
                  </svg>
                  Domingo: Miramar
                </a>
              </li>
              <li>
                <a href="https://maps.app.goo.gl/9nBdVH7qwkhHuY5X7" target="_blank" rel="noopener noreferrer" className="lnk-menu hover-brown">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="svg-icons-small color-brown"
                    viewBox="0 0 512 512"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      stroke="none">
                      <path d="M435 4836 c-79 -35 -94 -84 -95 -314 l0 -182 -170 -340 -170 -340 0
                                                    -142 c0 -180 11 -253 48 -333 52 -110 139 -187 257 -227 l50 -17 3 -1040 2
                                                    -1041 -88 0 c-74 0 -96 -4 -139 -25 -105 -53 -133 -120 -133 -322 0 -161 9
                                                    -196 59 -226 27 -16 189 -17 2501 -17 2312 0 2474 1 2501 17 50 30 59 65 59
                                                    226 0 202 -28 269 -133 322 -43 21 -65 25 -139 25 l-88 0 2 1041 3 1042 40 12
                                                    c134 40 233 134 285 270 20 52 23 84 28 245 l5 185 -169 335 -169 335 -5 205
                                                    c-5 225 -11 248 -73 294 l-28 21 -1751 3 -1752 2 -23 -22 c-40 -38 -25 -96 31
                                                    -118 18 -7 578 -10 1737 -10 l1710 0 -3 -147 -3 -148 -2065 0 -2065 0 -3 148
                                                    -3 147 179 0 c105 0 192 5 211 11 54 19 69 79 28 117 -22 21 -31 22 -233 22
                                                    -151 -1 -217 -4 -239 -14z m482 -598 c-2 -7 -44 -124 -93 -260 l-88 -248 -268
                                                    0 -268 0 130 260 130 260 231 0 c181 0 230 -3 226 -12z m630 0 c-2 -7 -28
                                                    -124 -57 -260 l-54 -248 -268 0 c-147 0 -268 4 -268 8 0 4 41 120 90 257 50
                                                    137 90 251 90 252 0 2 106 3 236 3 185 0 235 -3 231 -12z m623 0 c0 -7 -7
                                                    -124 -17 -260 l-17 -248 -273 0 -273 0 5 23 c10 43 105 489 105 493 0 2 106 4
                                                    235 4 181 0 235 -3 235 -12z m639 -160 c6 -95 15 -212 18 -260 l6 -88 -273 0
                                                    -273 0 6 88 c3 48 12 165 18 260 l12 172 237 0 237 0 12 -172z m611 168 c0 -4
                                                    95 -450 105 -493 l5 -23 -273 0 -273 0 -17 248 c-10 136 -17 253 -17 260 0 9
                                                    54 12 235 12 129 0 235 -2 235 -4z m620 1 c0 -1 41 -115 90 -252 50 -137 90
                                                    -253 90 -257 0 -4 -121 -8 -268 -8 l-268 0 -54 248 c-29 136 -55 253 -57 260
                                                    -4 9 46 12 231 12 130 0 236 -1 236 -3z m750 -257 l130 -260 -268 0 -268 0
                                                    -88 248 c-49 136 -91 253 -93 260 -4 9 45 12 226 12 l231 0 130 -260z m-4080
                                                    -548 c0 -135 -1 -140 -31 -201 -54 -107 -149 -159 -274 -149 -103 9 -183 66
                                                    -226 163 -19 41 -23 73 -27 188 l-5 137 282 0 281 0 0 -138z m708 -9 c-3 -129
                                                    -6 -153 -25 -189 -112 -212 -407 -205 -504 11 -19 42 -24 72 -27 188 l-4 137
                                                    282 0 282 0 -4 -147z m712 17 c0 -152 -17 -212 -77 -274 -110 -113 -296 -115
                                                    -400 -5 -65 70 -77 109 -81 267 l-4 142 281 0 281 0 0 -130z m710 7 c0 -152
                                                    -14 -207 -72 -272 -113 -128 -303 -128 -416 0 -58 65 -72 120 -72 272 l0 123
                                                    280 0 280 0 0 -123z m708 -19 c-3 -126 -6 -148 -26 -188 -94 -180 -317 -217
                                                    -455 -74 -60 62 -77 122 -77 274 l0 130 281 0 281 0 -4 -142z m710 5 c-3 -116
                                                    -8 -146 -27 -188 -97 -216 -392 -223 -504 -11 -19 36 -22 60 -25 189 l-4 147
                                                    282 0 282 0 -4 -137z m710 0 c-4 -115 -8 -147 -27 -188 -43 -97 -123 -154
                                                    -226 -163 -125 -10 -220 42 -274 149 -30 61 -31 66 -31 201 l0 138 281 0 282
                                                    0 -5 -137z m-4038 -451 c65 -38 146 -55 240 -50 74 3 98 9 156 38 41 20 90 55
                                                    120 85 l50 52 66 -62 c93 -88 149 -109 293 -110 105 0 114 2 179 34 39 19 90
                                                    55 121 87 l54 54 27 -35 c36 -47 132 -110 198 -129 69 -20 183 -20 252 0 66
                                                    19 162 82 198 129 l27 35 54 -54 c31 -32 82 -68 121 -87 65 -32 74 -34 179
                                                    -34 144 1 200 22 293 110 l66 62 50 -52 c30 -30 79 -65 120 -85 58 -29 82 -35
                                                    156 -38 141 -7 239 30 334 127 l48 49 54 -54 c56 -56 109 -88 178 -108 l41
                                                    -12 3 -1042 2 -1042 -1410 0 -1410 0 0 846 c0 936 3 889 -64 937 l-31 22 -480
                                                    0 c-529 0 -507 3 -552 -63 -17 -26 -18 -74 -21 -884 l-2 -858 -65 0 -65 0 2
                                                    1042 3 1042 41 12 c69 20 122 52 177 108 l52 53 55 -52 c30 -28 71 -61 90 -73z
                                                    m710 -1302 l0 -830 -425 0 -425 0 0 830 0 830 425 0 425 0 0 -830z m3301
                                                    -1009 c29 -29 29 -31 29 -145 l0 -116 -2410 0 -2410 0 0 116 c0 114 0 116 29
                                                    145 l29 29 2352 0 2352 0 29 -29z"/>
                      <path d="M2025 2650 c-23 -11 -50 -35 -60 -52 -19 -32 -20 -54 -20 -668 0
                                                    -592 1 -637 18 -668 10 -18 32 -42 50 -52 31 -19 59 -20 1163 -20 l1131 0 34
                                                    23 c72 47 69 21 69 712 0 593 -1 622 -20 663 -15 34 -29 47 -64 63 -43 19 -65
                                                    19 -525 17 -455 -3 -480 -4 -500 -22 -27 -25 -28 -79 -1 -106 19 -19 33 -20
                                                    490 -20 l470 0 0 -590 0 -590 -1080 0 -1080 0 0 590 0 590 463 0 464 0 21 23
                                                    c27 29 28 67 3 98 l-19 24 -484 3 c-467 2 -484 2 -523 -18z"/>
                      <path d="M1405 1848 c-39 -21 -45 -39 -45 -131 0 -100 7 -119 49 -136 24 -10
                                                    34 -10 59 3 38 20 42 31 42 131 0 99 -4 111 -41 130 -33 18 -38 18 -64 3z"/>
                    </g>
                  </svg>
                  Lunes: Chulavista
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div id="menu-overlay" className="hidden"></div>

        <div>
          <a href="https://api.whatsapp.com/send?phone=+5213319999015&text=Hola!%20Me%20gustar%C3%ADa%20realizar%20una%20consulta." className="float" target="_blank" rel="noreferrer">
            <i className="fa fa-whatsapp my-float"></i>
          </a>
        </div>

      </header>
    );
  }

}

export default Header;
