import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => {
  return (
    <Helmet>
      <title>Farmacia Homeopática San Rafael Arcángel</title>
      <meta name="description" content="Homeopática San Rafael Arcángel" />
      <meta name="keywords" content="homeopatica, natural, san, rafael, arcangel, medicina, homeopatia, homeopatía" />
      <meta property="og:title" content="Homeopática San Rafael Arcángel" />
      <meta property="og:description" content="Description of your website" />
      <meta property="og:image" content="https://images.pexels.com/photos/6694176/pexels-photo-6694176.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
    </Helmet>
  );
};

export default SEO;