import React from 'react';

function Privacy(props) {

    (function init() {
        window.scrollTo(0, 0)
    })();
    
    return (
        <div>
            <br /><br />
            <div className="container">
                <div className="card card-body">
                    <center><h2 className="uppercase bold">Aviso de Privacidad</h2></center>
                    <br />
                    <h5 className="mb-0 mt-0 bold">El presente Aviso de Privacidad forma parte del uso del sitio Web www.homeopaticasanrafael.com</h5>
                    <br />
                    <ul className="list-yes">
                        <li>En cumplimiento con lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de
                            los Particulares y su Reglamento,  Farmacia Homeopática San Rafael Arcángel hace de su conocimiento
                            el Aviso de Privacidad y manejo de datos personales, en la que en todo momento buscarán que su tratamiento
                            sea legítimo, controlado e informado, a efecto de garantizar su privacidad.</li>
                        <br />
                        <li>Por lo anterior, el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos
                            Personales (INAI) es el organismo constitucional autónomo garante del cumplimiento de los derechos
                            fundamentales que la Ley y el Reglamento antes mencionados protegen, dicho organismo es el encargado
                            de promover el conocimiento del derecho a la protección de datos personales en la sociedad mexicana,
                            su ejercicio y vigilar la debida observancia de las disposiciones antes mencionadas.</li>
                        <br />
                        <li>Farmacia Homeopática San Rafael Arcángel se compromete a asegurar la privacidad de la información personal
                            obtenida a través de sus servicios en línea. No obstante, se sugiere leer la normativa descrita a
                            continuación para entender el tratamiento de los datos proporcionados.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Responsable</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Farmacia Homeopática San Rafael Arcángel cuyo propietario se acredita como una Persona Física
                            registrada en el SAT  como Ma Cristina Corona Cordova es el responsable del tratamiento de sus
                            datos personales, del uso que se le dé a los mismos y de su protección. Así mismo declara el propietario
                            que se encuentra tributando bajo el Régimen Simplificado de Confianza con RFC: COCC680311J62  y con
                            domicilio fiscal en Calle Paseos de La Primavera 84, Arenales Tapatíos, C.P. 45066, en Zapopan, Jalisco.</li>
                        <br />
                        <li>Farmacia Homeopática San Rafael Arcángel informará al usuario qué tipo de datos recolecta,
                            cómo los almacena, la finalidad del archivo, cómo los protege, el alcance de su compromiso de
                            confidencialidad y los derechos que éste posee como titular de la información.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Tipo de información</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Se recopilará la información de diferentes formas y desde distintas áreas del
                            sitio Web: www.homeopaticasanrafael.com</li>
                        <br />
                        <li>Se sujetarán a las normas de seguridad y privacidad todos aquellos datos personales
                            que el usuario ingrese voluntariamente en dicha dirección electrónica.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Se solicita la siguiente información:</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Nombre completo</li>
                        <li>Correo electrónico</li>
                        <li>Dirección</li>
                        <li>Ciudad</li>
                        <li>Estado</li>
                        <li>Código Postal</li>
                        <li>País</li>
                        <li>Teléfono</li>
                        <li>Celular</li>
                        <li>Datos fiscales para elaboración de comprobantes</li>
                        <li>Datos de Tarjeta de Crédito</li>
                        <li>El sitio www.homeopaticasanrafael.com no solicita datos personales sensibles.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase">Uso de la información</h4>
                    <br />
                    <ul className="list-yes">
                        <li>La información solicitada permitirá dar cumplimiento a las obligaciones contraídas
                            con nuestros clientes, contactar a los clientes y usuarios cuando sea necesario
                            por teléfono, whatsapp o correo electrónico en caso de que se requieran datos adicionales
                            para completar alguna transacción.</li>
                        <br />
                        <li>La información suministrada durante el proceso de registro, inscripción a algún programa
                            y promociones se emplea para la generación de una cuenta con usuario y contraseña para
                            realizar compras, así como para realizar estudios internos sobre los datos demográficos,
                            intereses y comportamiento de los usuarios; con la finalidad de proporcionarles productos,
                            servicios, contenidos y publicidad acordes a sus necesidades.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Protección de la Información</h4>
                    <br />
                    <ul className="list-yes">
                        <li>La seguridad y la confidencialidad de los datos que los usuarios proporcionen al comprar
                            un producto en línea estarán protegidos por un servidor seguro bajo el protocolo Secure
                            Socket Layer (SSL), de tal forma que los datos enviados se transmitirán encriptados
                            para asegurar su resguardo.</li>
                        <br />
                        <li>Al momento de contratar un servicio en línea, se pedirán datos bancarios para los cuales
                            se les direcciona a una página Web que ofrece seguridad y confidencialidad de los datos
                            que proporciona, para ello, cuentan con un servidor seguro bajo el protocolo SSL
                            (Secure Socket Layer) de tal manera que la información que envían, se transmite encriptada
                            para asegurar su protección. </li>
                        <br />
                        <li>Para verificar que se encuentra en un entorno protegido asegúrese de que aparezca una S en la
                            barra de navegación. Ejemplo: https://.</li>
                        <br />
                        <li>Sin embargo, y a pesar de contar cada día con herramientas más seguras, la protección de los
                            datos enviados a través de Internet no se puede garantizar al 100%; por lo que una vez recibidos,
                            se hará todo lo posible por salvaguardar la información.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Confidencialidad</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Cuando el usuario se encuentre en el sitio web (www.homeopaticasanrafael.com),compartirá su
                            información con Farmacia Homeopática San Rafael Arcángel.</li>
                        <br />
                        <li>Farmacia Homeopática San Rafael Arcángel no tiene obligación alguna de mantener confidencial
                            cualquier otra información que el usuario proporcione por medio de boletines y pláticas en
                            línea (chats), así como a través de las cookies.</li>
                        <br />
                        <li>Sólo se podrá difundir la información en casos especiales, como identificar, localizar o
                            realizar acciones legales contra aquellas personas que infrinjan las condiciones de servicio
                            del sitio web (www.homeopaticasanrafael.com), causen daños a, o interfieran en, los derechos
                            de Farmacia Homeopática San Rafael Arcángel, sus propiedades, de otros usuarios del portal o
                            de cualquier persona que pudiese resultar perjudicada por dichas acciones.</li>
                        <br />
                        <li>Los usuarios tienen todo el derecho de acudir al INAI (Instituto Nacional de Transparencia,
                            Acceso a la Información y Protección de Datos Personales) en caso de considerar que ha sido
                            vulnerado su derecho a la protección de datos personales.</li>
                        <br />
                        <li>Farmacia Homeopática San Rafael Arcángel NO venderá, regalará, facilitará ni alquilará información
                            confidencial a terceros.</li>
                        <br />
                        <li>Farmacia Homeopática San Rafael Arcángel NO compartirá información confidencial con terceras partes,
                            salvo por una orden judicial que se requiera para cumplir con ciertas disposiciones procesales.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">“USO DE “WEB BEACONS” Y “COOKIES”</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Algunas partes del sitio web de www.homeopaticasanrafael.com pueden utilizar "Cookies" y
                            “web beacons” para simplificar la navegación. Las cookies son archivos de texto que son
                            descargados automáticamente y almacenados en el disco duro del equipo de cómputo del
                            usuario al navegar en una página de Internet específica, que permiten recordar al servidor
                            de Internet algunos datos sobre este usuario, entre ellos, sus preferencias de compra para
                            la visualización de las páginas en ese servidor, nombre y contraseña. Por su parte, las
                            “web beacons” son imágenes insertadas en una página de Internet o correo electrónico,
                            que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar
                            información sobre la dirección IP del usuario, duración del tiempo de interacción en dicha
                            página y el tipo de navegador utilizado, entre otros. Le informamos que utilizamos cookies
                            y web beacons para obtener información personal de usted, como la siguiente:
                            i) El tipo de navegador y sistema operativo que utiliza;
                            ii) Las páginas de Internet que visita en forma previa y posterior a la entrada del Sitio Web de
                            “Farmacia Homeopática San Rafael Arcángel”; iii) Los vínculos que sigue y permanencia en nuestro
                            sitio; iv) Su dirección IP; v) Lugar desde el cual nos visita y estadísticas de navegación.
                            Estas cookies y otras tecnologías pueden ser deshabilitadas. Puede buscar información sobre los
                            navegadores conocidos y averiguar cómo ajustar las preferencias de las cookies en los siguientes
                            sitios web: Microsoft Internet Explorer: http://www.microsoft.com/info/cookies.htm Mozilla
                            Firefox: http://www.mozilla.org/projects/security/pki/psm/help_21/using_priv_help.html En el
                            caso de empleo de cookies, el botón de "ayuda" que se encuentra en la barra de herramientas de
                            la mayoría de los navegadores, le dirá cómo evitar aceptar nuevas cookies, cómo hacer que el
                            navegador le notifique cuando recibe una nueva cookie o cómo deshabilitar todas las cookies.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Limitación de uso y divulgación de la información</h4>
                    <br />
                    <ul className="list-yes">
                        <li>En nuestro programa de notificación de promociones, ofertas y servicios a través de correo
                            electrónico, sólo Farmacia Homeopática San Rafael Arcángel tiene acceso a la información
                            recabada. Este tipo de publicidad se realiza mediante avisos y mensajes promocionales de
                            correo electrónico, los cuales sólo serán enviados a usted y a aquellos contactos
                            registrados para tal propósito, esta indicación podrá usted modificarla en cualquier
                            momento enviando un correo a privacidad@homeopaticamty.com  En los correos electrónicos
                            enviados, pueden incluirse ocasionalmente ofertas de terceras partes que sean nuestros socios
                            comerciales.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Derechos de ACCESO, RECTIFICACIÓN, CANCELACIÓN Y
                        OPOSICIÓN de los datos personales de los Usuarios</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Los datos personales proporcionados por el usuario formarán parte de un archivo que
                            contendrá su perfil. El usuario puede acceder o modificar su perfil en cualquier
                            momento utilizando su número de usuario y contraseña o enviándonos un correo dirigido a
                            nuestro responsable de protección de datos.</li>
                        <br />
                        <li>Asimismo, en cualquier momento podrá revocar el consentimiento al tratamiento de sus datos,
                            por el mismo medio.</li>
                        <br />
                        <li>Su solicitud deberá ir acompañada de los argumentos por los que desea ejercer cualquiera de
                            sus derechos, junto con una identificación oficial del titular de los datos o su apoderado,
                            su petición será atendida en un plazo no mayor a 20 días hábiles.</li>
                        <br />
                        <li>Farmacia Homeopática San Rafael Arcángel aconseja al usuario que actualice sus datos cada vez que
                            éstos sufran alguna modificación, ya que esto permitirá brindarle un servicio más personalizado.</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Modificaciones en el Aviso de Privacidad</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Farmacia Homeopática San Rafael Arcángel se reserva el derecho de efectuar en
                            cualquier momento, modificaciones en al presente Aviso de Privacidad y adaptarla a
                            novedades legislativas, jurisprudenciales, así como a prácticas del mercado.
                            Se notificarán periódicamente las adecuaciones realizadas en el presente aviso
                            de privacidad, la cual serán introducidas en el sitio sección Aviso de Privacidad
                            y entrarán en vigencia automáticamente. </li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Legislación aplicable</h4>
                    <br />
                    <ul className="list-yes">
                        <li>LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES,
                            Arts. 1 al 69 y transitorios. Publicada en el DOF el 05-07-2010.</li>
                        <br />
                        <li>http://www.diputados.gob.mx/LeyesBiblio/pdf/LFPDPPP.pdf</li>
                    </ul>

                    <br /><br />
                    <h4 className="mb-0 mt-0 uppercase bold">Aceptación de los Términos</h4>
                    <br />
                    <ul className="list-yes">
                        <li>Si el usuario utiliza los servicios en cualquiera de los sitios de Farmacia
                            Homeopática San Rafael Arcángel, significa que ha leído, entendido y acordado
                            los términos antes expuestos, Aviso de Privacidad actualizado con fecha: 01-01-2024.</li>
                    </ul>
                    <br /><br />
                </div>
            </div>
        </div>
    );
}

export default Privacy;
