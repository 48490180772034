import React from "react";
import Slider from "react-slick";

export default function ProductsList() {
    var settings = {
        customPaging: function (i) {
            return (
                <div className="carousel-item-preview">
                    <img src={`/catalogo/catalogo${i + 1}.jpg`} alt="" />
                </div>
            );
        },
        dots: true,
        fade: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false
    };


    let catalogoList = [
        {
            id: "q01",
            src: "/catalogo/catalogo1.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo2.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo3.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo4.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo5.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo6.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo7.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo8.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo9.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo10.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo11.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo12.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo13.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo14.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo15.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo16.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo17.jpg",
        },
        {
            id: "q01",
            src: "/catalogo/catalogo18.jpg",
        }
    ];

    let products = catalogoList
        .map(aff => {
            return (
                <div key={aff.id} className="carousel-item-catalogo">
                    <center>
                        <img src={aff.src} alt="" />

                    </center>
                </div>
            );
        });


    return (
        <div className="carousel-container-catalogo">
            <Slider {...settings}>
                {products}
            </Slider>
            <br /><br />
        </div>
    );
}