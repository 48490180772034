import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import { Footer, Header, Terms, Privacy, NotFound, Homeopatia, Contact, Products } from './components';


function LandingPage() {
    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/inicio" element={<Home />} />
                <Route path="/homeopatia" element={<Homeopatia />} />
                <Route path="/productos" element={<Products />} />
                <Route path="/contacto" element={<Contact />} />
                <Route path="/terminos-y-condiciones" element={<Terms />} />
                <Route path="/aviso-de-privacidad" element={<Privacy />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default LandingPage;
