import React from 'react';

function NotFound(props) {

    (function init() {
        window.scrollTo(0, 0)
    })();

    return (
        <div className="container-boo">
            <div className="boo-wrapper">
                <div className="boo">
                    <div className="face"></div>
                </div>
                <div className="shadow"></div>

                <h1>Uuuups!</h1>
                <p>
                    La página que quieres visitar
                    <br />
                    No existe.
                </p>
            </div>
        </div>
    );
}

export default NotFound;
