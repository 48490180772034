import React from "react";

function Homeopatia(props) {

    (function init() {
        window.scrollTo(0, 0)
    })();

    return (
        <div>
            <div className="wallpaperHomeopatia">
                <div className="textAbout">
                    <h3 id="about" className="bounceInDown animated">HOMEOPATÍA</h3>
                </div>
            </div>

            <div className="container container-75">
                <div className=" card-body">
                    <center>
                        <h2 className="bold primary-green">¿Qué es Homeopatía?</h2>
                        <div className="border-bottom-header"></div>
                    </center>
                    <p>Un sistema terapéutico eficiente. Los medicamentos homeopáticos contienen la dosis
                        mínima indispensable para desencadenar los estímulos y reacciones necesarias en el organismo,
                        <i className="bold"> con el fin de corregir los desequilibrios que originan las enfermedades </i>
                        brindando un estado de salud de manera rápida y de forma permanente.
                    </p>
                    <br />


                    <section className="container ">
                        <h4 className="bold primary-green">Beneficios y ventajas</h4>
                        <div className="border-bottom-header"></div>
                        <p>Eficaz para prevenir, controlar o eliminar los trastornos causados por las diferentes enfermedades.
                            Los medicamentos homeopáticos se caracterizan por estar elaborados con materias primas de origen natural
                            que ayudan al cuerpo en su proceso natural de curación.
                        </p>
                        <br />
                        <p>
                            Se puede utilizar junto con otros tratamientos sin interferir en ellos.
                        </p>
                        <br />

                        <ul className="ul-icons list-unstyled">
                            <li>
                                <p><i className="fa fa-check-circle"></i> Son medicamentos seguros y efectivos</p>
                            </li>
                            <li>
                                <p><i className="fa fa-check-circle"></i> Son medicamentos útiles para personas alérgicas</p>
                            </li>
                            <li>
                                <p><i className="fa fa-check-circle"></i> Son medicamentos seguros en niños</p>
                            </li>
                            <li>
                                <p><i className="fa fa-check-circle"></i> Son medicamentos seguros en adultos mayores</p>
                            </li>
                            <li>
                                <p><i className="fa fa-check-circle"></i> Son medicamentos complementarios en enfermedades crónicas</p>
                            </li>
                            <li>
                                <p><i className="fa fa-check-circle"></i> Son medicamentos con acción integral</p>
                            </li>
                            <li>
                                <p><i className="fa fa-check-circle"></i> Son medicamentos con nula toxicidad</p>
                            </li>
                        </ul>
                        <br />
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Homeopatia;
