import React, { Component } from "react";
import { Collapse } from 'reactstrap';

class Question extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }


  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    let question = this.props.question;
    let information = this.props.information;

    return (
      <div>
        <div className="question" onClick={this.toggle} >
          <h4>
            {question}
          </h4>
        </div>

        <Collapse isOpen={this.state.collapse}>
          <div className="spaceBotton card card-body">
            <h5>{information}</h5>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default Question;
