import React from 'react';
import ViewSDKClient from "./ViewSDKClient.js";

const PreviewCatalog = ({ url }) => {
    const loadPDF = () => {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile(
                "pdf-div",
                {
                    embedMode: 'LIGHT_BOX',
                    showAnnotationTools: false,
                    showLeftHandPanel: false,
                    showPageControls: false,
                    showPrintPDF: false
                },
                url
            );
        });
    };
    return (
        <div>
            <button id="view-pdf-btn" onClick={() => loadPDF()}>
                <i className="fa fa-file-pdf-o white-icon"> </i> Ver Catálogo de Productos
            </button>
        </div>
    );
};
export default PreviewCatalog;