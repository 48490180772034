import React from "react";
import FAQs from "./FAQs"

function Contact(props) {

    (function init() {
        window.scrollTo(0, 0)
    })();

    return (
        <div>
            <div className="wallpaperContacto">
            </div>

            <div className="container">
                <div className="card-body">
                    <center>
                        <h2 className="bold primary-green">Estamos para ayudarte</h2>
                        <div className="border-bottom-header"></div>
                    </center>

                    <section>
                        <div className="row text-center">
                            <div className="col-md-4">
                                <div className="box-simple">
                                    <i className="fa fa-clock-o icons-home"></i>
                                    <h3 className="h4 bold">Horario de Atención</h3>
                                    <p>Actualmente nuestro horario de atención <i className="bold">en farmacia</i> es de:
                                        <br />
                                        <i className="bold">Martes a Viernes 2 pm - 7 pm.</i>
                                    </p>
                                    <br />
                                    <p>Puedes visitarnos, llamarnos o mandarnos un Whatsapp dentro de este horario.
                                    </p>
                                    <br /><br />
                                    <center>
                                        <div className="border-bottom-header none-mobile"></div>
                                    </center>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box-simple">
                                    <a href="https://maps.app.goo.gl/DuukERQa2c1sMPhi7" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="svg-icons" id="Capa_1" viewBox="0 0 512 512">
                                            <g>
                                                <path d="m499.924 113.264c-173.716-34.527-162.471-32.724-166.405-32.062-1.258.21-16.676 3.309-52.613 10.496-15.066-43.444-56.399-74.721-104.906-74.721-48.552 0-89.918 31.336-104.949 74.844l-53.127-10.557c-9.26-1.836-17.924 5.243-17.924 14.713v352c0 7.157 5.057 13.317 12.076 14.712 172.248 34.236 160.971 32.026 162.113 32.165 3.622.445-7.572 2.297 161.82-31.582l158.067 31.417c9.265 1.839 17.924-5.252 17.924-14.712v-352c0-7.157-5.057-13.317-12.076-14.713zm-323.924-66.287c44.663 0 81 36.336 81 81 0 37.683-60.298 133.338-81 164.989-20.679-31.616-81-127.298-81-164.989 0-44.664 36.337-81 81-81zm-146 67.273 35.21 6.997c-.134 2.227-.21 4.469-.21 6.729 0 25.014 16.363 65.381 50.025 123.409 19.656 33.883 39.033 62.974 45.975 73.221v137.095l-131-26.037zm161 210.356c6.941-10.247 26.319-39.337 45.975-73.221 33.662-58.028 50.025-98.395 50.025-123.408 0-2.304-.079-4.59-.218-6.859l34.218-6.845v321.407l-130 26zm291 137.096-131-26.037v-321.414l131 26.037z">
                                                </path>
                                                <path d="m176 174.977c25.916 0 47-21.084 47-47s-21.084-47-47-47-47 21.084-47 47 21.084 47 47 47zm0-64c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z">
                                                </path>
                                            </g>
                                        </svg>
                                        <div>
                                            <h3 className="h4 bold">Dirección</h3>
                                            <p><i className="bold primary-green">Visítanos en Calle Paseos de la Primavera 84</i>, Col. Arenales Tapatíos, Zapopan, Jalisco.</p>
                                        </div>
                                    </a>
                                    <br /><br />
                                    <center>
                                        <div className="border-bottom-header none-mobile"></div>
                                    </center>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box-simple">
                                    <i className="fa fa-phone icons-home"></i>
                                    <h3 className="h4 bold">Teléfono</h3>
                                    <p>Para contactarnos puede marcar al siguiente número: <br />
                                        <a className="bold primary-green" href="tel:+52331-999-9015">+52 33 19 99 90 15</a>
                                        <br />
                                        También puede contactarnos directamente por <br /> <a className="bold primary-green" href="https://api.whatsapp.com/send?phone=+5213319999015&text=Hola!%20Me%20gustar%C3%ADa%20realizar%20una%20consulta." target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp"></i> Whatsapp</a>.
                                    </p>
                                    <br />
                                    <center>
                                        <div className="border-bottom-wavy none-mobile">              </div>
                                        <br />
                                    </center>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div className="container">
                <div className="card-body">
                    <center>
                        <h2 className="bold primary-green">También nos puedes encontrar en un horario de 9 am a 2 pm en...</h2>
                        <br /><br />
                    </center>

                    <section>
                        <div className="row text-center">
                            <div className="col-md-4">
                                <div className="box-simple">
                                    <a href="https://maps.app.goo.gl/tYhDQT7GVJK9z51a9" className="hover-orange" target="_blank" rel="noreferrer">
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="svg-icons color-orange"
                                            viewBox="0 0 512 512"
                                            preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                stroke="none">
                                                <path d="M1145 5106 c-68 -31 -95 -91 -95 -212 l0 -71 -132 -6 c-74 -3 -160 -13 -193 -22 -211 -59 -396 -208 -489 -398 -89 -181 -81 6 -81 -1987 l0 -1765 23 -80 c40 -136 95 -227 202 -335 107 -108 197 -162 336 -202 l79 -23 1765 0 1765 0 79 23 c139 40 229 94 336 202 107 108 162 199 202 335 l23 80 0 1765 c0 1993 8 1806 -81 1987 -93 190 -278 339 -489 398 -33 9 -119 19 -192 22 l-133 6 0 71 c0 39 -5 89 -11 111 -36 134 -212 154 -276 32 -10 -20 -18 -67 -21 -124 l-5 -93 -523 0 -524 0 0 93 c0 71 -4 100 -19 127 -22 42 -84 80 -131 80 -47 0 -109 -38 -131 -80 -15 -27 -19 -56 -19 -127 l0 -93 -524 0 -523 0 -5 94 c-6 102 -21 143 -69 179 -34 25 -105 31 -144 13z m-93 -833 c3 -234 4 -250 25 -283 29 -47 71 -70 128 -70 57 0 99 23 128 70 21 33 22 49 25 283 l4 247 524 0 524 0 0 -242 c0 -272 4 -292 69 -335 71 -48 175 -20 213 57 16 30 18 67 18 278 l0 242 524 0 524 0 4 -247 c3 -234 4 -250 25 -283 29 -47 71 -70 128 -70 57 0 99 23 128 70 21 33 22 49 25 283 l4 247 66 0 c260 -1 433 -114 508 -332 16 -46 19 -87 19 -313 l0 -260 -2105 0 -2105 0 0 260 c0 226 3 267 19 313 45 131 126 224 243 279 79 38 137 50 255 52 l76 1 4 -247z m3616 -2280 c-3 -1261 -4 -1320 -22 -1367 -59 -154 -158 -251 -306 -302 -53 -18 -116 -19 -1780 -19 -1664 0 -1727 1 -1780 19 -148 51 -247 148 -306 302 -18 47 -19 106 -22 1367 l-3 1317 2111 0 2111 0 -3 -1317z" />
                                                <path d="M720 2361 c-111 -36 -168 -117 -158 -223 10 -94 52 -126 230 -170
                                                    149 -36 191 -56 208 -99 11 -26 11 -38 -1 -73 -20 -58 -77 -86 -174 -86 -94 1
                                                    -147 24 -174 79 -12 22 -21 49 -21 61 0 17 -6 20 -45 20 -43 0 -45 -1 -45 -30
                                                    0 -69 52 -145 124 -182 109 -56 317 -30 388 49 51 56 63 162 25 228 -29 51
                                                    -79 78 -192 105 -146 35 -189 50 -212 76 -29 32 -29 72 0 115 29 43 83 62 158
                                                    56 81 -7 117 -29 142 -88 21 -48 22 -49 65 -49 l45 0 -7 43 c-17 107 -104 169
                                                    -246 173 -47 2 -96 0 -110 -5z"/>
                                                <path d="M4114 2360 c-95 -29 -176 -122 -211 -239 -24 -84 -14 -228 21 -307
                                                    62 -137 196 -206 361 -183 187 25 310 204 291 421 -12 138 -69 231 -172 283
                                                    -52 26 -70 30 -159 32 -55 1 -114 -2 -131 -7z m233 -98 c88 -45 126 -119 127
                                                    -247 2 -204 -97 -317 -264 -302 -143 13 -220 111 -220 281 0 155 58 247 180
                                                    287 40 13 133 3 177 -19z"/>
                                                <path d="M1432 2348 c-13 -16 -262 -676 -262 -694 0 -18 74 -19 88 -1 6 7 26
                                                    55 45 107 l33 95 136 3 c100 2 138 -1 145 -10 6 -7 23 -51 38 -98 15 -47 32
                                                    -91 38 -97 11 -15 83 -17 92 -3 6 9 -230 675 -248 698 -5 6 -29 12 -52 12 -23
                                                    0 -47 -6 -53 -12z m102 -256 c25 -75 46 -140 46 -145 0 -4 -47 -7 -105 -7 -58
                                                    0 -105 2 -105 5 0 16 104 285 111 285 4 0 28 -62 53 -138z"/>
                                                <path d="M1885 2349 c-3 -8 -4 -169 -3 -359 l3 -345 175 1 c209 1 249 10 306
                                                    68 76 76 83 184 17 257 -19 21 -41 39 -49 39 -26 0 -25 21 4 45 41 34 55 75
                                                    50 140 -6 69 -29 102 -92 134 -44 22 -63 25 -227 29 -146 4 -180 2 -184 -9z
                                                    m365 -94 c54 -28 63 -120 16 -164 -28 -26 -131 -44 -228 -39 l-63 3 -3 108 -3
                                                    107 126 0 c92 0 134 -4 155 -15z m17 -298 c44 -24 63 -54 63 -104 0 -35 -6
                                                    -50 -34 -81 l-34 -37 -144 0 -143 0 -3 109 c-1 61 0 116 3 124 8 22 247 12
                                                    292 -11z"/>
                                                <path d="M2752 2348 c-13 -16 -262 -676 -262 -694 0 -18 74 -19 88 -1 6 7 26
                                                    55 45 107 l33 95 136 3 c100 2 138 -1 145 -10 6 -7 23 -51 38 -98 15 -47 32
                                                    -91 38 -97 11 -15 83 -17 92 -3 6 9 -230 675 -248 698 -5 6 -29 12 -52 12 -23
                                                    0 -47 -6 -53 -12z m102 -256 c25 -75 46 -140 46 -145 0 -4 -47 -7 -105 -7 -58
                                                    0 -105 2 -105 5 0 16 104 285 111 285 4 0 28 -62 53 -138z"/>
                                                <path d="M3210 1998 l0 -361 181 6 c169 5 183 7 235 32 69 34 116 95 145 188
                                                    31 100 24 249 -15 327 -69 137 -148 169 -418 170 l-128 0 0 -362z m358 257
                                                    c54 -23 79 -51 103 -114 40 -106 23 -269 -35 -346 -40 -51 -91 -66 -221 -63
                                                    l-110 3 -3 255 c-1 140 0 260 3 267 7 20 215 18 263 -2z"/>
                                            </g>
                                        </svg>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="svg-icons color-orange"
                                            viewBox="0 0 512 512"
                                            preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                stroke="none">
                                                <path d="M435 4836 c-79 -35 -94 -84 -95 -314 l0 -182 -170 -340 -170 -340 0
                                                    -142 c0 -180 11 -253 48 -333 52 -110 139 -187 257 -227 l50 -17 3 -1040 2
                                                    -1041 -88 0 c-74 0 -96 -4 -139 -25 -105 -53 -133 -120 -133 -322 0 -161 9
                                                    -196 59 -226 27 -16 189 -17 2501 -17 2312 0 2474 1 2501 17 50 30 59 65 59
                                                    226 0 202 -28 269 -133 322 -43 21 -65 25 -139 25 l-88 0 2 1041 3 1042 40 12
                                                    c134 40 233 134 285 270 20 52 23 84 28 245 l5 185 -169 335 -169 335 -5 205
                                                    c-5 225 -11 248 -73 294 l-28 21 -1751 3 -1752 2 -23 -22 c-40 -38 -25 -96 31
                                                    -118 18 -7 578 -10 1737 -10 l1710 0 -3 -147 -3 -148 -2065 0 -2065 0 -3 148
                                                    -3 147 179 0 c105 0 192 5 211 11 54 19 69 79 28 117 -22 21 -31 22 -233 22
                                                    -151 -1 -217 -4 -239 -14z m482 -598 c-2 -7 -44 -124 -93 -260 l-88 -248 -268
                                                    0 -268 0 130 260 130 260 231 0 c181 0 230 -3 226 -12z m630 0 c-2 -7 -28
                                                    -124 -57 -260 l-54 -248 -268 0 c-147 0 -268 4 -268 8 0 4 41 120 90 257 50
                                                    137 90 251 90 252 0 2 106 3 236 3 185 0 235 -3 231 -12z m623 0 c0 -7 -7
                                                    -124 -17 -260 l-17 -248 -273 0 -273 0 5 23 c10 43 105 489 105 493 0 2 106 4
                                                    235 4 181 0 235 -3 235 -12z m639 -160 c6 -95 15 -212 18 -260 l6 -88 -273 0
                                                    -273 0 6 88 c3 48 12 165 18 260 l12 172 237 0 237 0 12 -172z m611 168 c0 -4
                                                    95 -450 105 -493 l5 -23 -273 0 -273 0 -17 248 c-10 136 -17 253 -17 260 0 9
                                                    54 12 235 12 129 0 235 -2 235 -4z m620 1 c0 -1 41 -115 90 -252 50 -137 90
                                                    -253 90 -257 0 -4 -121 -8 -268 -8 l-268 0 -54 248 c-29 136 -55 253 -57 260
                                                    -4 9 46 12 231 12 130 0 236 -1 236 -3z m750 -257 l130 -260 -268 0 -268 0
                                                    -88 248 c-49 136 -91 253 -93 260 -4 9 45 12 226 12 l231 0 130 -260z m-4080
                                                    -548 c0 -135 -1 -140 -31 -201 -54 -107 -149 -159 -274 -149 -103 9 -183 66
                                                    -226 163 -19 41 -23 73 -27 188 l-5 137 282 0 281 0 0 -138z m708 -9 c-3 -129
                                                    -6 -153 -25 -189 -112 -212 -407 -205 -504 11 -19 42 -24 72 -27 188 l-4 137
                                                    282 0 282 0 -4 -147z m712 17 c0 -152 -17 -212 -77 -274 -110 -113 -296 -115
                                                    -400 -5 -65 70 -77 109 -81 267 l-4 142 281 0 281 0 0 -130z m710 7 c0 -152
                                                    -14 -207 -72 -272 -113 -128 -303 -128 -416 0 -58 65 -72 120 -72 272 l0 123
                                                    280 0 280 0 0 -123z m708 -19 c-3 -126 -6 -148 -26 -188 -94 -180 -317 -217
                                                    -455 -74 -60 62 -77 122 -77 274 l0 130 281 0 281 0 -4 -142z m710 5 c-3 -116
                                                    -8 -146 -27 -188 -97 -216 -392 -223 -504 -11 -19 36 -22 60 -25 189 l-4 147
                                                    282 0 282 0 -4 -137z m710 0 c-4 -115 -8 -147 -27 -188 -43 -97 -123 -154
                                                    -226 -163 -125 -10 -220 42 -274 149 -30 61 -31 66 -31 201 l0 138 281 0 282
                                                    0 -5 -137z m-4038 -451 c65 -38 146 -55 240 -50 74 3 98 9 156 38 41 20 90 55
                                                    120 85 l50 52 66 -62 c93 -88 149 -109 293 -110 105 0 114 2 179 34 39 19 90
                                                    55 121 87 l54 54 27 -35 c36 -47 132 -110 198 -129 69 -20 183 -20 252 0 66
                                                    19 162 82 198 129 l27 35 54 -54 c31 -32 82 -68 121 -87 65 -32 74 -34 179
                                                    -34 144 1 200 22 293 110 l66 62 50 -52 c30 -30 79 -65 120 -85 58 -29 82 -35
                                                    156 -38 141 -7 239 30 334 127 l48 49 54 -54 c56 -56 109 -88 178 -108 l41
                                                    -12 3 -1042 2 -1042 -1410 0 -1410 0 0 846 c0 936 3 889 -64 937 l-31 22 -480
                                                    0 c-529 0 -507 3 -552 -63 -17 -26 -18 -74 -21 -884 l-2 -858 -65 0 -65 0 2
                                                    1042 3 1042 41 12 c69 20 122 52 177 108 l52 53 55 -52 c30 -28 71 -61 90 -73z
                                                    m710 -1302 l0 -830 -425 0 -425 0 0 830 0 830 425 0 425 0 0 -830z m3301
                                                    -1009 c29 -29 29 -31 29 -145 l0 -116 -2410 0 -2410 0 0 116 c0 114 0 116 29
                                                    145 l29 29 2352 0 2352 0 29 -29z"/>
                                                <path d="M2025 2650 c-23 -11 -50 -35 -60 -52 -19 -32 -20 -54 -20 -668 0
                                                    -592 1 -637 18 -668 10 -18 32 -42 50 -52 31 -19 59 -20 1163 -20 l1131 0 34
                                                    23 c72 47 69 21 69 712 0 593 -1 622 -20 663 -15 34 -29 47 -64 63 -43 19 -65
                                                    19 -525 17 -455 -3 -480 -4 -500 -22 -27 -25 -28 -79 -1 -106 19 -19 33 -20
                                                    490 -20 l470 0 0 -590 0 -590 -1080 0 -1080 0 0 590 0 590 463 0 464 0 21 23
                                                    c27 29 28 67 3 98 l-19 24 -484 3 c-467 2 -484 2 -523 -18z"/>
                                                <path d="M1405 1848 c-39 -21 -45 -39 -45 -131 0 -100 7 -119 49 -136 24 -10
                                                    34 -10 59 3 38 20 42 31 42 131 0 99 -4 111 -41 130 -33 18 -38 18 -64 3z"/>
                                            </g>
                                        </svg>
                                        <div>
                                            <h3 className="h4 bold color-orange">TIANGUIS DEL SAUZ</h3>
                                            <p>Los Sábados nos ubicamos en <i className="bold color-orange">Calle Isla Zanzibar, Frente al sitio de Taxis 63,</i> Col. El Sauz, Guadalajara, Jalisco.</p>
                                        </div>
                                    </a>
                                    <br /><br />
                                    <center>
                                        <div className="border-bottom-header none-mobile"></div>
                                    </center>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box-simple">
                                    <a href="https://maps.app.goo.gl/ghty4DumyCnPGU4bA" className="hover-blue" target="_blank" rel="noreferrer">
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="svg-icons color-blue"
                                            viewBox="0 0 512 512"
                                            preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                stroke="none">
                                                <path d="M1145 5106 c-68 -31 -95 -91 -95 -212 l0 -71 -132 -6 c-74 -3 -160
                                                    -13 -193 -22 -211 -59 -396 -208 -489 -398 -89 -181 -81 6 -81 -1987 l0 -1765
                                                    23 -80 c40 -136 95 -227 202 -335 107 -108 197 -162 336 -202 l79 -23 1765 0
                                                    1765 0 79 23 c139 40 229 94 336 202 107 108 162 199 202 335 l23 80 0 1765
                                                    c0 1993 8 1806 -81 1987 -93 190 -278 339 -489 398 -33 9 -119 19 -192 22
                                                    l-133 6 0 71 c0 39 -5 89 -11 111 -36 134 -212 154 -276 32 -10 -20 -18 -67
                                                    -21 -124 l-5 -93 -523 0 -524 0 0 93 c0 71 -4 100 -19 127 -22 42 -84 80 -131
                                                    80 -47 0 -109 -38 -131 -80 -15 -27 -19 -56 -19 -127 l0 -93 -524 0 -523 0 -5
                                                    94 c-6 102 -21 143 -69 179 -34 25 -105 31 -144 13z m-93 -833 c3 -234 4 -250
                                                    25 -283 29 -47 71 -70 128 -70 57 0 99 23 128 70 21 33 22 49 25 283 l4 247
                                                    524 0 524 0 0 -242 c0 -272 4 -292 69 -335 71 -48 175 -20 213 57 16 30 18 67
                                                    18 278 l0 242 524 0 524 0 4 -247 c3 -234 4 -250 25 -283 29 -47 71 -70 128
                                                    -70 57 0 99 23 128 70 21 33 22 49 25 283 l4 247 66 0 c260 -1 433 -114 508
                                                    -332 16 -46 19 -87 19 -313 l0 -260 -2105 0 -2105 0 0 260 c0 226 3 267 19
                                                    313 45 131 126 224 243 279 79 38 137 50 255 52 l76 1 4 -247z m3616 -2280
                                                    c-3 -1261 -4 -1320 -22 -1367 -59 -154 -158 -251 -306 -302 -53 -18 -116 -19
                                                    -1780 -19 -1664 0 -1727 1 -1780 19 -148 51 -247 148 -306 302 -18 47 -19 106
                                                    -22 1367 l-3 1317 2111 0 2111 0 -3 -1317z"/>
                                                <path d="M1357 2290 c-76 -13 -132 -46 -173 -101 -46 -63 -65 -125 -65 -218 0
                                                    -197 108 -314 291 -314 150 0 246 76 286 225 19 73 17 145 -5 217 -39 127
                                                    -104 175 -261 196 -14 1 -46 -1 -73 -5z m149 -89 c76 -35 114 -109 114 -222 0
                                                    -116 -49 -210 -123 -238 -45 -17 -137 -13 -184 8 -128 58 -153 314 -41 418 57
                                                    53 159 68 234 34z"/>
                                                <path d="M3582 2289 c-70 -9 -136 -51 -178 -112 -43 -63 -57 -113 -57 -212 -1
                                                    -144 52 -240 155 -285 97 -42 237 -23 292 39 26 29 34 27 44 -11 6 -26 14 -34
                                                    35 -36 l27 -3 0 166 0 166 -132 -3 -133 -3 -3 -32 -3 -33 95 0 96 0 0 -33 c0
                                                    -52 -39 -119 -85 -145 -34 -19 -52 -22 -116 -20 -65 3 -81 7 -112 30 -87 67
                                                    -105 272 -34 379 69 105 271 104 315 -1 17 -41 41 -56 79 -48 24 5 19 40 -13
                                                    95 -45 78 -150 117 -272 102z"/>
                                                <path d="M4237 2290 c-76 -13 -132 -46 -173 -101 -46 -63 -65 -125 -65 -218 0
                                                    -197 108 -314 291 -314 150 0 246 76 286 225 19 73 17 145 -5 217 -39 127
                                                    -104 175 -261 196 -14 1 -46 -1 -73 -5z m149 -89 c76 -35 114 -109 114 -222 0
                                                    -116 -49 -210 -123 -238 -45 -17 -137 -13 -184 8 -128 58 -153 314 -41 418 57
                                                    53 159 68 234 34z"/>
                                                <path d="M540 1975 l0 -307 163 4 c155 3 164 4 208 30 90 53 134 157 127 302
                                                    -6 129 -45 202 -133 249 -35 19 -60 22 -202 25 l-163 4 0 -307z m330 210 c100
                                                    -59 119 -283 33 -386 -40 -47 -79 -59 -188 -59 l-95 0 0 236 0 236 108 -3 c85
                                                    -3 114 -8 142 -24z"/>
                                                <path d="M1800 1975 l0 -305 40 0 40 0 1 248 c1 288 -7 290 101 -30 l73 -218
                                                    42 0 41 0 84 248 83 247 3 -247 2 -248 40 0 40 0 0 305 0 305 -59 0 -59 0 -38
                                                    -112 c-20 -62 -59 -178 -87 -257 l-50 -144 -87 254 -86 254 -62 3 -62 3 0
                                                    -306z"/>
                                                <path d="M2537 2273 c-4 -3 -7 -141 -7 -305 l0 -298 45 0 45 0 0 305 0 305
                                                    -38 0 c-21 0 -42 -3 -45 -7z"/>
                                                <path d="M2760 1975 l0 -305 40 0 40 0 2 239 3 239 150 -238 150 -239 48 -1
                                                    47 0 0 305 0 305 -40 0 -40 0 -2 -242 -3 -243 -150 243 -150 242 -47 0 -48 0
                                                    0 -305z"/>
                                            </g>
                                        </svg>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="svg-icons color-blue"
                                            viewBox="0 0 512 512"
                                            preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                stroke="none">
                                                <path d="M435 4836 c-79 -35 -94 -84 -95 -314 l0 -182 -170 -340 -170 -340 0
                                                    -142 c0 -180 11 -253 48 -333 52 -110 139 -187 257 -227 l50 -17 3 -1040 2
                                                    -1041 -88 0 c-74 0 -96 -4 -139 -25 -105 -53 -133 -120 -133 -322 0 -161 9
                                                    -196 59 -226 27 -16 189 -17 2501 -17 2312 0 2474 1 2501 17 50 30 59 65 59
                                                    226 0 202 -28 269 -133 322 -43 21 -65 25 -139 25 l-88 0 2 1041 3 1042 40 12
                                                    c134 40 233 134 285 270 20 52 23 84 28 245 l5 185 -169 335 -169 335 -5 205
                                                    c-5 225 -11 248 -73 294 l-28 21 -1751 3 -1752 2 -23 -22 c-40 -38 -25 -96 31
                                                    -118 18 -7 578 -10 1737 -10 l1710 0 -3 -147 -3 -148 -2065 0 -2065 0 -3 148
                                                    -3 147 179 0 c105 0 192 5 211 11 54 19 69 79 28 117 -22 21 -31 22 -233 22
                                                    -151 -1 -217 -4 -239 -14z m482 -598 c-2 -7 -44 -124 -93 -260 l-88 -248 -268
                                                    0 -268 0 130 260 130 260 231 0 c181 0 230 -3 226 -12z m630 0 c-2 -7 -28
                                                    -124 -57 -260 l-54 -248 -268 0 c-147 0 -268 4 -268 8 0 4 41 120 90 257 50
                                                    137 90 251 90 252 0 2 106 3 236 3 185 0 235 -3 231 -12z m623 0 c0 -7 -7
                                                    -124 -17 -260 l-17 -248 -273 0 -273 0 5 23 c10 43 105 489 105 493 0 2 106 4
                                                    235 4 181 0 235 -3 235 -12z m639 -160 c6 -95 15 -212 18 -260 l6 -88 -273 0
                                                    -273 0 6 88 c3 48 12 165 18 260 l12 172 237 0 237 0 12 -172z m611 168 c0 -4
                                                    95 -450 105 -493 l5 -23 -273 0 -273 0 -17 248 c-10 136 -17 253 -17 260 0 9
                                                    54 12 235 12 129 0 235 -2 235 -4z m620 1 c0 -1 41 -115 90 -252 50 -137 90
                                                    -253 90 -257 0 -4 -121 -8 -268 -8 l-268 0 -54 248 c-29 136 -55 253 -57 260
                                                    -4 9 46 12 231 12 130 0 236 -1 236 -3z m750 -257 l130 -260 -268 0 -268 0
                                                    -88 248 c-49 136 -91 253 -93 260 -4 9 45 12 226 12 l231 0 130 -260z m-4080
                                                    -548 c0 -135 -1 -140 -31 -201 -54 -107 -149 -159 -274 -149 -103 9 -183 66
                                                    -226 163 -19 41 -23 73 -27 188 l-5 137 282 0 281 0 0 -138z m708 -9 c-3 -129
                                                    -6 -153 -25 -189 -112 -212 -407 -205 -504 11 -19 42 -24 72 -27 188 l-4 137
                                                    282 0 282 0 -4 -147z m712 17 c0 -152 -17 -212 -77 -274 -110 -113 -296 -115
                                                    -400 -5 -65 70 -77 109 -81 267 l-4 142 281 0 281 0 0 -130z m710 7 c0 -152
                                                    -14 -207 -72 -272 -113 -128 -303 -128 -416 0 -58 65 -72 120 -72 272 l0 123
                                                    280 0 280 0 0 -123z m708 -19 c-3 -126 -6 -148 -26 -188 -94 -180 -317 -217
                                                    -455 -74 -60 62 -77 122 -77 274 l0 130 281 0 281 0 -4 -142z m710 5 c-3 -116
                                                    -8 -146 -27 -188 -97 -216 -392 -223 -504 -11 -19 36 -22 60 -25 189 l-4 147
                                                    282 0 282 0 -4 -137z m710 0 c-4 -115 -8 -147 -27 -188 -43 -97 -123 -154
                                                    -226 -163 -125 -10 -220 42 -274 149 -30 61 -31 66 -31 201 l0 138 281 0 282
                                                    0 -5 -137z m-4038 -451 c65 -38 146 -55 240 -50 74 3 98 9 156 38 41 20 90 55
                                                    120 85 l50 52 66 -62 c93 -88 149 -109 293 -110 105 0 114 2 179 34 39 19 90
                                                    55 121 87 l54 54 27 -35 c36 -47 132 -110 198 -129 69 -20 183 -20 252 0 66
                                                    19 162 82 198 129 l27 35 54 -54 c31 -32 82 -68 121 -87 65 -32 74 -34 179
                                                    -34 144 1 200 22 293 110 l66 62 50 -52 c30 -30 79 -65 120 -85 58 -29 82 -35
                                                    156 -38 141 -7 239 30 334 127 l48 49 54 -54 c56 -56 109 -88 178 -108 l41
                                                    -12 3 -1042 2 -1042 -1410 0 -1410 0 0 846 c0 936 3 889 -64 937 l-31 22 -480
                                                    0 c-529 0 -507 3 -552 -63 -17 -26 -18 -74 -21 -884 l-2 -858 -65 0 -65 0 2
                                                    1042 3 1042 41 12 c69 20 122 52 177 108 l52 53 55 -52 c30 -28 71 -61 90 -73z
                                                    m710 -1302 l0 -830 -425 0 -425 0 0 830 0 830 425 0 425 0 0 -830z m3301
                                                    -1009 c29 -29 29 -31 29 -145 l0 -116 -2410 0 -2410 0 0 116 c0 114 0 116 29
                                                    145 l29 29 2352 0 2352 0 29 -29z"/>
                                                <path d="M2025 2650 c-23 -11 -50 -35 -60 -52 -19 -32 -20 -54 -20 -668 0
                                                    -592 1 -637 18 -668 10 -18 32 -42 50 -52 31 -19 59 -20 1163 -20 l1131 0 34
                                                    23 c72 47 69 21 69 712 0 593 -1 622 -20 663 -15 34 -29 47 -64 63 -43 19 -65
                                                    19 -525 17 -455 -3 -480 -4 -500 -22 -27 -25 -28 -79 -1 -106 19 -19 33 -20
                                                    490 -20 l470 0 0 -590 0 -590 -1080 0 -1080 0 0 590 0 590 463 0 464 0 21 23
                                                    c27 29 28 67 3 98 l-19 24 -484 3 c-467 2 -484 2 -523 -18z"/>
                                                <path d="M1405 1848 c-39 -21 -45 -39 -45 -131 0 -100 7 -119 49 -136 24 -10
                                                    34 -10 59 3 38 20 42 31 42 131 0 99 -4 111 -41 130 -33 18 -38 18 -64 3z"/>
                                            </g>
                                        </svg>

                                        <div>
                                            <h3 className="h4 bold color-blue">TIANGUIS DE MIRAMAR</h3>
                                            <p>Los Domingos nos ubicamos en <i className="bold color-blue">Av. Puerto Mazatlan Esq. con Puerto Chamela,</i> Col. Miramar, Zapopan, Jalisco.</p>
                                        </div>
                                    </a>
                                    <br /><br />
                                    <center>
                                        <div className="border-bottom-header none-mobile"></div>
                                    </center>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box-simple">
                                    <a href="https://maps.app.goo.gl/9nBdVH7qwkhHuY5X7" className="hover-brown" target="_blank" rel="noreferrer">
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="svg-icons color-brown"
                                            viewBox="0 0 512 512"
                                            preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                stroke="none">
                                                <path d="M1145 5106 c-68 -31 -95 -91 -95 -212 l0 -71 -132 -6 c-74 -3 -160
                                                    -13 -193 -22 -211 -59 -396 -208 -489 -398 -89 -181 -81 6 -81 -1987 l0 -1765
                                                    23 -80 c40 -136 95 -227 202 -335 107 -108 197 -162 336 -202 l79 -23 1765 0
                                                    1765 0 79 23 c139 40 229 94 336 202 107 108 162 199 202 335 l23 80 0 1765
                                                    c0 1993 8 1806 -81 1987 -93 190 -278 339 -489 398 -33 9 -119 19 -192 22
                                                    l-133 6 0 71 c0 39 -5 89 -11 111 -36 134 -212 154 -276 32 -10 -20 -18 -67
                                                    -21 -124 l-5 -93 -523 0 -524 0 0 93 c0 71 -4 100 -19 127 -22 42 -84 80 -131
                                                    80 -47 0 -109 -38 -131 -80 -15 -27 -19 -56 -19 -127 l0 -93 -524 0 -523 0 -5
                                                    94 c-6 102 -21 143 -69 179 -34 25 -105 31 -144 13z m-93 -833 c3 -234 4 -250
                                                    25 -283 29 -47 71 -70 128 -70 57 0 99 23 128 70 21 33 22 49 25 283 l4 247
                                                    524 0 524 0 0 -242 c0 -272 4 -292 69 -335 71 -48 175 -20 213 57 16 30 18 67
                                                    18 278 l0 242 524 0 524 0 4 -247 c3 -234 4 -250 25 -283 29 -47 71 -70 128
                                                    -70 57 0 99 23 128 70 21 33 22 49 25 283 l4 247 66 0 c260 -1 433 -114 508
                                                    -332 16 -46 19 -87 19 -313 l0 -260 -2105 0 -2105 0 0 260 c0 226 3 267 19
                                                    313 45 131 126 224 243 279 79 38 137 50 255 52 l76 1 4 -247z m3616 -2280
                                                    c-3 -1261 -4 -1320 -22 -1367 -59 -154 -158 -251 -306 -302 -53 -18 -116 -19
                                                    -1780 -19 -1664 0 -1727 1 -1780 19 -148 51 -247 148 -306 302 -18 47 -19 106
                                                    -22 1367 l-3 1317 2111 0 2111 0 -3 -1317z"/>
                                                <path d="M4032 2409 c-105 -14 -169 -55 -208 -135 -22 -46 -26 -64 -22 -122 7
                                                    -123 54 -158 283 -212 156 -37 205 -61 222 -111 15 -48 2 -108 -32 -136 -63
                                                    -55 -217 -68 -300 -25 -59 30 -81 62 -99 145 -6 25 -10 27 -56 27 -50 0 -50 0
                                                    -50 -33 0 -91 68 -189 158 -229 73 -33 220 -37 312 -9 130 40 186 118 178 250
                                                    -3 60 -8 75 -34 108 -46 56 -76 70 -247 112 -178 45 -210 59 -227 100 -38 92
                                                    48 181 176 181 106 0 180 -48 201 -130 9 -34 11 -35 61 -38 51 -3 52 -2 52 24
                                                    0 154 -166 259 -368 233z"/>
                                                <path d="M730 1980 l0 -410 265 0 265 0 0 50 0 50 -210 0 -210 0 0 360 0 360
                                                    -55 0 -55 0 0 -410z"/>
                                                <path d="M1383 2088 c2 -200 8 -316 15 -340 24 -72 73 -131 136 -163 56 -28
                                                    66 -30 170 -29 125 0 161 12 225 69 83 75 100 166 101 523 l0 242 -59 0 -60 0
                                                    -3 -308 -3 -309 -27 -40 c-36 -55 -96 -83 -176 -83 -97 0 -143 26 -185 105
                                                    -21 38 -22 55 -25 338 l-3 297 -55 0 -55 0 4 -302z"/>
                                                <path d="M2200 1980 l0 -410 55 0 55 0 2 327 3 327 205 -325 206 -324 62 -3
                                                    62 -3 0 411 0 410 -50 0 -50 0 0 -326 c0 -179 -4 -323 -8 -320 -4 2 -98 149
                                                    -207 325 l-200 321 -67 0 -68 0 0 -410z"/>
                                                <path d="M3040 1980 l0 -410 305 0 305 0 0 50 0 50 -250 0 -250 0 0 140 0 140
                                                    225 0 225 0 0 45 0 45 -225 0 -225 0 0 125 0 125 245 0 245 0 0 50 0 50 -300
                                                    0 -300 0 0 -410z"/>
                                            </g>
                                        </svg>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="svg-icons color-brown"
                                            viewBox="0 0 512 512"
                                            preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                stroke="none">
                                                <path d="M435 4836 c-79 -35 -94 -84 -95 -314 l0 -182 -170 -340 -170 -340 0
                                                    -142 c0 -180 11 -253 48 -333 52 -110 139 -187 257 -227 l50 -17 3 -1040 2
                                                    -1041 -88 0 c-74 0 -96 -4 -139 -25 -105 -53 -133 -120 -133 -322 0 -161 9
                                                    -196 59 -226 27 -16 189 -17 2501 -17 2312 0 2474 1 2501 17 50 30 59 65 59
                                                    226 0 202 -28 269 -133 322 -43 21 -65 25 -139 25 l-88 0 2 1041 3 1042 40 12
                                                    c134 40 233 134 285 270 20 52 23 84 28 245 l5 185 -169 335 -169 335 -5 205
                                                    c-5 225 -11 248 -73 294 l-28 21 -1751 3 -1752 2 -23 -22 c-40 -38 -25 -96 31
                                                    -118 18 -7 578 -10 1737 -10 l1710 0 -3 -147 -3 -148 -2065 0 -2065 0 -3 148
                                                    -3 147 179 0 c105 0 192 5 211 11 54 19 69 79 28 117 -22 21 -31 22 -233 22
                                                    -151 -1 -217 -4 -239 -14z m482 -598 c-2 -7 -44 -124 -93 -260 l-88 -248 -268
                                                    0 -268 0 130 260 130 260 231 0 c181 0 230 -3 226 -12z m630 0 c-2 -7 -28
                                                    -124 -57 -260 l-54 -248 -268 0 c-147 0 -268 4 -268 8 0 4 41 120 90 257 50
                                                    137 90 251 90 252 0 2 106 3 236 3 185 0 235 -3 231 -12z m623 0 c0 -7 -7
                                                    -124 -17 -260 l-17 -248 -273 0 -273 0 5 23 c10 43 105 489 105 493 0 2 106 4
                                                    235 4 181 0 235 -3 235 -12z m639 -160 c6 -95 15 -212 18 -260 l6 -88 -273 0
                                                    -273 0 6 88 c3 48 12 165 18 260 l12 172 237 0 237 0 12 -172z m611 168 c0 -4
                                                    95 -450 105 -493 l5 -23 -273 0 -273 0 -17 248 c-10 136 -17 253 -17 260 0 9
                                                    54 12 235 12 129 0 235 -2 235 -4z m620 1 c0 -1 41 -115 90 -252 50 -137 90
                                                    -253 90 -257 0 -4 -121 -8 -268 -8 l-268 0 -54 248 c-29 136 -55 253 -57 260
                                                    -4 9 46 12 231 12 130 0 236 -1 236 -3z m750 -257 l130 -260 -268 0 -268 0
                                                    -88 248 c-49 136 -91 253 -93 260 -4 9 45 12 226 12 l231 0 130 -260z m-4080
                                                    -548 c0 -135 -1 -140 -31 -201 -54 -107 -149 -159 -274 -149 -103 9 -183 66
                                                    -226 163 -19 41 -23 73 -27 188 l-5 137 282 0 281 0 0 -138z m708 -9 c-3 -129
                                                    -6 -153 -25 -189 -112 -212 -407 -205 -504 11 -19 42 -24 72 -27 188 l-4 137
                                                    282 0 282 0 -4 -147z m712 17 c0 -152 -17 -212 -77 -274 -110 -113 -296 -115
                                                    -400 -5 -65 70 -77 109 -81 267 l-4 142 281 0 281 0 0 -130z m710 7 c0 -152
                                                    -14 -207 -72 -272 -113 -128 -303 -128 -416 0 -58 65 -72 120 -72 272 l0 123
                                                    280 0 280 0 0 -123z m708 -19 c-3 -126 -6 -148 -26 -188 -94 -180 -317 -217
                                                    -455 -74 -60 62 -77 122 -77 274 l0 130 281 0 281 0 -4 -142z m710 5 c-3 -116
                                                    -8 -146 -27 -188 -97 -216 -392 -223 -504 -11 -19 36 -22 60 -25 189 l-4 147
                                                    282 0 282 0 -4 -137z m710 0 c-4 -115 -8 -147 -27 -188 -43 -97 -123 -154
                                                    -226 -163 -125 -10 -220 42 -274 149 -30 61 -31 66 -31 201 l0 138 281 0 282
                                                    0 -5 -137z m-4038 -451 c65 -38 146 -55 240 -50 74 3 98 9 156 38 41 20 90 55
                                                    120 85 l50 52 66 -62 c93 -88 149 -109 293 -110 105 0 114 2 179 34 39 19 90
                                                    55 121 87 l54 54 27 -35 c36 -47 132 -110 198 -129 69 -20 183 -20 252 0 66
                                                    19 162 82 198 129 l27 35 54 -54 c31 -32 82 -68 121 -87 65 -32 74 -34 179
                                                    -34 144 1 200 22 293 110 l66 62 50 -52 c30 -30 79 -65 120 -85 58 -29 82 -35
                                                    156 -38 141 -7 239 30 334 127 l48 49 54 -54 c56 -56 109 -88 178 -108 l41
                                                    -12 3 -1042 2 -1042 -1410 0 -1410 0 0 846 c0 936 3 889 -64 937 l-31 22 -480
                                                    0 c-529 0 -507 3 -552 -63 -17 -26 -18 -74 -21 -884 l-2 -858 -65 0 -65 0 2
                                                    1042 3 1042 41 12 c69 20 122 52 177 108 l52 53 55 -52 c30 -28 71 -61 90 -73z
                                                    m710 -1302 l0 -830 -425 0 -425 0 0 830 0 830 425 0 425 0 0 -830z m3301
                                                    -1009 c29 -29 29 -31 29 -145 l0 -116 -2410 0 -2410 0 0 116 c0 114 0 116 29
                                                    145 l29 29 2352 0 2352 0 29 -29z"/>
                                                <path d="M2025 2650 c-23 -11 -50 -35 -60 -52 -19 -32 -20 -54 -20 -668 0
                                                    -592 1 -637 18 -668 10 -18 32 -42 50 -52 31 -19 59 -20 1163 -20 l1131 0 34
                                                    23 c72 47 69 21 69 712 0 593 -1 622 -20 663 -15 34 -29 47 -64 63 -43 19 -65
                                                    19 -525 17 -455 -3 -480 -4 -500 -22 -27 -25 -28 -79 -1 -106 19 -19 33 -20
                                                    490 -20 l470 0 0 -590 0 -590 -1080 0 -1080 0 0 590 0 590 463 0 464 0 21 23
                                                    c27 29 28 67 3 98 l-19 24 -484 3 c-467 2 -484 2 -523 -18z"/>
                                                <path d="M1405 1848 c-39 -21 -45 -39 -45 -131 0 -100 7 -119 49 -136 24 -10
                                                    34 -10 59 3 38 20 42 31 42 131 0 99 -4 111 -41 130 -33 18 -38 18 -64 3z"/>
                                            </g>
                                        </svg>
                                        <div>
                                            <h3 className="h4 bold color-brown">TIANGUIS DE CHULAVISTA</h3>
                                            <p>Los Lunes nos ubicamos en <i className="bold color-brown">Av. Cerro de Alcalá Esq. con Calle Cerro Hueco,</i> Col. Chulavista, Hacienda Santa Fe, Jalisco.</p>
                                        </div>
                                    </a>
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <FAQs></FAQs>
        </div>
    );
}

export default Contact;
