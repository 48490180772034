//8f90a62b42794620af4a4df1fea11fcd local
//d1fadf0f2995462fbec06d9d49ed0951 website

class ViewSDKClient {
    constructor() {
        this.readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
    }

    ready() {
        return this.readyPromise;
    }

    previewFile(divId, viewerConfig, url) {
        const config = {
            clientId: "d1fadf0f2995462fbec06d9d49ed0951" //Enter your Client ID here
        };
        if (divId) {
            config.divId = divId;
        }
        this.adobeDCView = new window.AdobeDC.View(config);
        const previewFilePromise = this.adobeDCView.previewFile(
            {
                content: {
                    location: {
                        url: url
                    }
                },
                metaData: {
                    fileName: "Catalogo-HomeopaticaSanRafael.pdf",
                }
            },
            viewerConfig
        );

        return previewFilePromise;
    }
}
export default ViewSDKClient;