import React, { Component } from "react";
import { Afecciones, PreviewCatalog } from './components';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      productsFilter: [],
      articles: [],
      testimonials: [],
      title: ""
    };

  }

  render() {

    (function init() {
      window.scrollTo(0, 0)
    })();


    let floresList = [
      {
        id: "f01",
        title: "Flores de Bach"
      },
      {
        id: "f02",
        title: "Orquídeas Amazónicas"
      },
      {
        id: "f03",
        title: "Plantas Medicinales"
      },
      {
        id: "f04",
        title: "Tinturas"
      },
      {
        id: "f05",
        title: "Extractos"
      },
      {
        id: "f06",
        title: "Pastillas"
      },
      {
        id: "f07",
        title: "Cápsulas"
      },
      {
        id: "f08",
        title: "Lociones"
      },
      {
        id: "f09",
        title: "Chochitos"
      },
      {
        id: "f10",
        title: "Miel, propóleo, Jalea Real y Jarabes"
      },
      {
        id: "f11",
        title: "Gotas para los ojos"
      }
    ];


    let productsList = [
      {
        id: "q01",
        title: "Shampoo"
      },
      {
        id: "q02",
        title: "Cremas para la belleza"
      },
      {
        id: "q03",
        title: "Cremas para peinar"
      },
      {
        id: "q04",
        title: "Cremas para el cuidado de la piel"
      },
      {
        id: "q05",
        title: "Geles"
      },
      {
        id: "q06",
        title: "Jabones artesanales"
      },
      {
        id: "q07",
        title: "Tratamientos reductores"
      },
      {
        id: "q08",
        title: "Aclarantes"
      },
      {
        id: "q09",
        title: "Aceites esenciales"
      },
      {
        id: "q10",
        title: "Sprays"
      }
    ];


    let flores = floresList
      .map(flw => {
        return (
          <li key={flw.id}>
            <p><i className="fa fa-envira"></i> {flw.title}</p>
          </li>
        );
      });



    let products = productsList
      .map(pdct => {
        return (
          <li key={pdct.id}>
            <p><i className="fa fa-check"></i> {pdct.title}</p>
          </li>
        );
      });

    return (
      <div>
        <div className="wallpaper"></div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-4 wrap-elements">
              <a href="https://maps.app.goo.gl/DuukERQa2c1sMPhi7" target="_blank" rel="noreferrer">
                <i className="fa fa-map-marker icons-home"></i>
                <div>
                  <h5 className="bold">Paseos de la Primavera 84</h5>
                  <p>Col. Arenales Tapatíos</p>
                  <p>Zapopan, Jalisco</p>
                </div>
                <br />
              </a>
            </div>
            <div className="col-sm-4 wrap-elements">
              <i className="fa fa-clock icons-home"></i>
              <div>
                <h5 className="bold">Solo abierto de</h5>
                <p>Martes a Viernes 2pm - 7 pm</p>
              </div>
              <br />
            </div>
          </div>
        </div>

        <section className="container container-center-70">
          <h4 className="bold primary-green">TRATAMIENTOS</h4>
          <div className="border-bottom-header"></div>
          <p>En enfermedades comunes y agudas, <i className="bold">ofrecemos el mejor tratamiento</i> en gotas homeopáticas,
            cápsulas, pastillas, cremas, lociones, extractos y tinturas.
          </p>
          <p>
            En enfermedades crónicas como la <i className="bold">diabetes, cáncer, lupus, hipertensión, etc.</i>,
            podemos ayudarte con nuestros tratamientos a mantener una estabilidad sin reacciones secundarias y
            sin daño a los órganos.
          </p>
          <span className="space"></span>
          <h4 className="bold">Tenemos tratamientos para cualquier tipo de afección</h4>
          <span className="space"></span>
        </section>

        <Afecciones></Afecciones>

        <section className="container">
          <div className="row justify-content-center">
            <div className="col-sm-6 banner-home-1">
              <div className="content">
                <h5 className="bold">SOMOS ESPECIALISTAS EN ATENCIÓN A </h5>
                <p>PACIENTES CON INSUFICIENCIA RENAL Y CÁNCER</p>
                <br />
              </div>
            </div>
            <div className="col-sm-6 banner-home-2">
              <div className="content">
                <h5 className="bold">LOS MEJORES TRATAMIENTOS PARA</h5>
                <p>CASOS ESPECIALES DE INFERTILIDAD</p>
              </div>
            </div>
          </div>
        </section>

        <section className="container container-center-70">
          <span className="space"></span>
          <h4 className="bold primary-green">10 AÑOS DE EXPERIENCIA</h4>
          <div className="border-bottom-header"></div>
          <p>Aunque parezca sencillo y se diga fácil, estos 10 años representan <i className="bold">arduos esfuerzos de
            investigación para ampliar nuestros conocimientos y mejorar cada día</i> en la atención al paciente.
          </p>
          <p>
            Nos dedicamos a satisfacer las necesidades dietéticas y proporcionar productos accesibles para
            <i className="bold"> aliviar padecimientos graves, incluso aquellos que parecen imposibles de tratar,
              hasta alcanzar la estabilidad</i> del paciente si no es posible restablecer su salud debido a la
            cronicidad de la enfermedad.
          </p>
          <span className="space-3"></span>
        </section>

        <section className="banner-green container-center">
          <div className="content">
            <h4 className="bold border-header">RECOMENDACIÓN DE PACIENTES</h4>
            <br />
            <p><i className="bold">Nuestros pacientes sanados nos recomiendan más que cualquier propaganda.</i></p>
            <p>Pregunta a conocidos o amigos, a alguien que haya sido nuestro paciente. Con confianza, nos recomiendan.
            </p>
            <p>
              Ven y compruébalo personalmente. <i className="bold">No tienes nada que perder y mucho que ganar </i>
              al curar tus enfermedades con métodos naturales.
            </p>
            <br />
          </div>
        </section>

        <section className="container container-center-70">
          <h4 className="bold primary-green">SERVICIOS</h4>
          <div className="border-bottom-header"></div>
          <p>Ofrecemos no solo la homeopatía en toda su  <i className="bold">amplia gama de potencias y organoterapéuticos
            (regeneradores)</i>, sino también:
          </p>
          <span className="space"></span>
          <div className="wrap-lists">
            <ul className="ul-icons list-unstyled">
              {flores}
            </ul>
            <ul className="ul-icons list-unstyled">
              {products}
            </ul>
          </div>
          <span className="space"></span>
          <p className="bold">
            Y muchos productos más...!
          </p>
          <span className="space"></span>
          <p>
            <i className="bold">Tenemos todo lo necesario para tu salud a precios enormemente baratos.</i>
          </p>
          <span className="space"></span>
          <PreviewCatalog
            url="https://firebasestorage.googleapis.com/v0/b/homeopatica-sanrafael.appspot.com/o/catalogo%2FCatalogo-HomeopaticaSanRafael.pdf?alt=media&token=ec2679f9-69cb-4385-9c9e-068d12215843"
          />

        </section>
      </div>
    );
  }
}
export default Home;
