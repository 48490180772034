import React from "react";
import Slider from "react-slick";

export default function Afecciones() {
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 3,
        speed: 500,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            }
        ]
    };


    let afectionsList = [
        {
            id: "q01",
            src: "/afecciones/afecciones-cardiovascular.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-dermatologico.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-digestivo.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-estetico.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-estres.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-geriatrico.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-ginecologico.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-musculo-esqueletico.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-Pediatrico.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-primeros-auxilios.png",
        },
        {
            id: "q01",
            src: "/afecciones/afecciones-respiratorio.png",
        }
    ];

    let afecciones = afectionsList
        .map(aff => {
            return (
                <div key={aff.id} className="carousel-item">
                    <img src={aff.src} alt="" />
                </div>
            );
        });


    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {afecciones}
            </Slider>
            <br /><br />
        </div>
    );
}